import React from "react"
import {connect} from 'react-redux'
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Card from "components/Card"
import CardBody from "components/CardBody"
import CardHeader from "components/CardHeader"

import extendedTablesStyle from "./userProfileStyles.jsx"

import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";

import ReactTable from "../../components/react-table";
import Button from "../../components/CustomButtons";
import {nimbusService} from "../../_services";
import {Alert} from "@mui/material";

class Continental extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      totalResults: 0,
      totalPages: 0,
      pageSize: 10,
      currentPage: 1,
      loading: false,
      data: [],
      in_validation: false,
    }

    this.timer = null
    this._is_mounted = false
    this._refresh_rate = 3000
  }

  componentDidMount() {
    this._is_mounted = true
    this.refresh()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this._is_mounted = false
  }

  refresh() {
    const {currentPage, pageSize} = this.state
    this.setState({loading: true})

    nimbusService.getResultsValidationContinentalSync(pageSize, currentPage - 1, undefined, result => {
      const total_pages = Math.ceil(result.total / pageSize)
      let newCurrentPage = currentPage
      if (currentPage > total_pages && result.total > 0) {
        newCurrentPage = total_pages
      }
      this.setState({
        totalResults: result.total,
        currentPage: newCurrentPage,
        data: result.results,
        totalPages: total_pages,
        loading: false,
      })
    }, error => {
      this.setState({
        data: [],
        totalPages: 0,
        loading: false
      })
    });
  }

  validate_sync() {
    this.setState({in_validation: true})
    nimbusService.validateContinentalSync(res => {
      this.setState({in_validation: false}, () => this.refresh())
    })
  }

  render() {
    const {currentPage, data, pageSize, loading, totalResults, totalPages, in_validation} = this.state

    let filler_size = (currentPage - 1) * pageSize
    if (filler_size < 0) {
      filler_size = 0
    }
    let filler_array = Array(filler_size).keys()

    if (!window.CONTINENTAL_INTEGRATION_ENABLED) {
      return <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader><h4 style={{color: primaryColor}}><b> Continental </b></h4></CardHeader>
            <CardBody>
              <Alert severity={"error"}> Integration is disabled, this is not possible at the moment</Alert>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    }

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              <GridContainer>
                <GridItem xs={10}>
                  <h4 style={{color: primaryColor}}><b>{"Continental"}</b></h4>
                </GridItem>
                <GridItem xs={2} style={{textAlign: "right"}}>
                  <Button color={"primary"} simple
                          onClick={this.validate_sync.bind(this)}><b>{"Validate Sync"}</b></Button>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              {in_validation && <Alert severity={"info"}> Validation is in progress... </Alert>}
              <h5># Results: {totalResults}</h5>
              <ReactTable
                columns={
                  [
                    {
                      Header: "Validation Time",
                      accessor: "validation_time",
                      Cell: row => {
                        return new Date(row.value).toLocaleString("nl-BE")
                      },
                    },
                    {
                      Header: "Error Message",
                      accessor: "error_message",
                    },
                    {
                      Header: "Continental ID",
                      accessor: "continental_id",
                    },
                    {
                      Header: "Nimbus ID",
                      accessor: "nimbus_id",
                    },
                  ]}
                data={[
                  ...filler_array,
                  ...data
                ]}
                sortable={false}
                filterable={false}
                loading={loading}
                pages={totalPages}
                page={currentPage - 1}
                pageSize={pageSize}
                onPageChange={(pageIndex) => this.setState({currentPage: pageIndex + 1}, () => this.refresh())}
                onPageSizeChange={(newPageSize) => {
                  this.setState({
                    currentPage: 1,
                    pageSize: newPageSize
                  }, () => this.refresh())
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}


function mapStateToProps(state) {
  const {total_nr_organisations, organisations} = state.location
  return {
    total_nr_organisations, organisations
  }
}


export default withStyles(extendedTablesStyle)(connect(mapStateToProps)(Continental))
