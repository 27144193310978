import StateFleet from "views/Fleet/StateFleet.jsx";
import MetricsFleet from "views/Fleet/MetricsFleet.jsx";
import Account from "views/Profile/Account.jsx";
import DeviceSpecificCommands from "views/Device/DeviceCommands.jsx";
import DeviceSpecificConfiguration from "views/Device/DeviceConfiguration.jsx";
import DeviceSpecificDetail from "views/Device/DeviceDetail.jsx";
import DeviceSpecificEvents from "views/Device/DeviceEvents.jsx";
import DeviceSpecificLogs from "views/Device/DeviceLogs.jsx";
import DeviceSpecificMaintenance from "views/Device/DeviceMaintenance.jsx";
import DeviceSpecificTreatments from "views/Device/DeviceTreatments.jsx";
import Maps from "views/Maps/Maps.jsx";
import MapDetail from "views/Maps/MapDetail.jsx";
import Locations from "views/Maps/Locations.jsx";
import Greenhouses from "views/Maps/Greenhouses.jsx";
import Organisations from "views/Maps/Organisations.jsx";
import Organisation from "views/Maps/Organisation.jsx";
import Authorisation from "views/Maps/Authorisation.jsx";
import Config from "views/Fleet/Config.jsx";
import Actions from "views/Fleet/Actions.jsx";
import ErrorCodes from "views/ErrorCodes/ErrorCodes.jsx";
import Incidents from "views/Incidents/Incidents.jsx";
import IncidentAnalytics from "views/Incidents/IncidentAnalytics.jsx";
import IncidentMaps from "views/Incidents/IncidentMaps.jsx";
import IncidentExport from "views/Incidents/IncidentExport.jsx";
import DeviceManagement from "views/DeviceManagement/DeviceManagement.jsx";
import DeviceInformation from "views/DeviceManagement/DeviceInformation.jsx";
import AddDevice from "views/DeviceManagement/AddDevice.jsx";
import DeviceLifeCycleStages from "views/DeviceManagement/DeviceLifeCycleStages.jsx";
import DeviceTypes from "views/DeviceManagement/DeviceTypes.jsx";
import Subscriptions from "views/DeviceManagement/Subscriptions.jsx";
import DeviceDeployment from "views/DeviceManagement/DeviceDeployment.jsx";
import Dashboard from "views/Dashboard/Dashboard.jsx";
import SettingsFleet from "views/Fleet/SettingsFleet.jsx";
import TreatmentAnalysis from "views/Treatments/TreatmentAnalysis";
import TreatmentDetail from "views/Treatments/TreatmentDetail";
import Treatments from "views/Treatments/Treatments";
import Continental from "views/NimbusManagement/Continental.jsx";

// @material-ui/icons
import List from "@material-ui/icons/ListTwoTone";
import TrackChanges from "@material-ui/icons/TrackChangesTwoTone";
import AccountCircle from "@material-ui/icons/AccountCircleTwoTone";
import Sunny from "@material-ui/icons/WbSunnyTwoTone";
import ARight from "@material-ui/icons/ArrowRightTwoTone";
import MapIcon from "@material-ui/icons/MapTwoTone";
import Settings from "@material-ui/icons/SettingsTwoTone";
import PendingActions from "@material-ui/icons/BuildTwoTone";
import Warning from "@material-ui/icons/WarningTwoTone";
import Lamp from "@material-ui/icons/HighlightTwoTone";
import Flash from "@material-ui/icons/FlashOnTwoTone";
import DashboardIcon from "@material-ui/icons/DashboardTwoTone";
import CompareArrowsIcon from '@material-ui/icons/CompareArrowsTwoTone';

const dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
  },
  {
    collapse: true,
    path: "/fleet",
    name: 'Fleet Overviews',
    state: "openFleet",
    icon: TrackChanges,
    views: [
      {
        path: "/fleet/state",
        name: "State Overview",
        icon: TrackChanges,
        component: StateFleet
      },
      {
        path: "/fleet/metrics",
        name: "Metrics",
        icon: List,
        component: MetricsFleet
      },
      {
        path: "/fleet/settings",
        name: "Settings",
        icon: Settings,
        component: SettingsFleet
      },
      {
        path: "/fleet/actions",
        name: "Actions",
        icon: PendingActions,
        component: Actions,
      },
      {
        path: "/fleet/config",
        name: "Config Compare",
        icon: CompareArrowsIcon,
        component: Config,
      },
    ]
  },
  {
    collapse: true,
    path: "/device",
    name: 'Device',
    state: "openDevice",
    icon: Sunny,
    views: [
      {
        path: "/device/detail",
        name: "State",
        icon: ARight,
        component: DeviceSpecificDetail
      },
      {
        path: "/device/logs",
        name: "Logs",
        icon: ARight,
        component: DeviceSpecificLogs
      },
      {
        path: "/device/events",
        name: "Events",
        icon: ARight,
        component: DeviceSpecificEvents
      },
      {
        path: "/device/commands",
        name: "Commands",
        icon: ARight,
        component: DeviceSpecificCommands
      },
      {
        path: "/device/configuration",
        name: "Configuration",
        icon: ARight,
        component: DeviceSpecificConfiguration
      },
      {
        path: "/device/treatments",
        name: "Treatments",
        icon: ARight,
        component: DeviceSpecificTreatments
      },
      {
        path: "/device/maintenance",
        name: "Maintenance",
        icon: ARight,
        component: DeviceSpecificMaintenance
      },
    ]
  },
  {
    collapse: true,
    path: "/maps",
    name: 'Deployment',
    state: "openMaps",
    icon: MapIcon,
    views: [
      {
        path: "/maps/maps",
        name: "Maps",
        icon: MapIcon,
        component: Maps
      },
      {
        path: "/maps/map_detail",
        name: "Map Detail",
        icon: MapIcon,
        component: MapDetail
      },
      {
        path: "/maps/greenhouses",
        name: "Greenhouses",
        icon: MapIcon,
        component: Greenhouses
      },
      {
        path: "/maps/locations",
        name: "Locations",
        icon: MapIcon,
        component: Locations
      },
      {
        path: "/maps/organisations",
        name: "Organisations",
        icon: MapIcon,
        component: Organisations
      },
      {
        path: "/maps/org_detail",
        name: "Organisation",
        icon: MapIcon,
        component: Organisation
      },
      {
        path: "/maps/authorisation",
        name: "Authorisation",
        icon: MapIcon,
        component: Authorisation
      },
    ]
  },
  {
    path: "/error_codes",
    name: "Error Codes",
    icon: Warning,
    component: ErrorCodes,
  },
  {
    collapse: true,
    path: "/treatments",
    name: 'Treatments',
    state: "openTreatments",
    icon: Flash,
    views: [
      {
        path: "/treatments/all",
        name: "All",
        icon: ARight,
        component: Treatments
      },
      {
        path: "/treatments/detail",
        name: "Detail",
        icon: ARight,
        component: TreatmentDetail
      },
      {
        path: "/treatments/event_analysis",
        name: "Event Analysis",
        icon: ARight,
        component: TreatmentAnalysis
      },
    ]
  },
  {
    collapse: true,
    path: "/incidents",
    name: 'Incidents',
    state: "openIncidents",
    icon: Lamp,
    views: [
      {
        path: "/incidents/all",
        name: "All Incidents",
        icon: ARight,
        component: Incidents
      },
      {
        path: "/incidents/analytics",
        name: "Analytics",
        icon: ARight,
        component: IncidentAnalytics
      },
      {
        path: "/incidents/per_map",
        name: "Per Map",
        icon: ARight,
        component: IncidentMaps
      },
      {
        path: "/incidents/export",
        name: "Export",
        icon: ARight,
        component: IncidentExport
      },
    ]
  },
  {
    collapse: true,
    path: "/management",
    name: 'Management',
    state: "openManagement",
    icon: Settings,
    views: [
      {
        path: "/management/overview",
        name: "Overview",
        icon: Settings,
        component: DeviceManagement
      },
      {
        path: "/management/device_info",
        name: "Device",
        icon: Settings,
        component: DeviceInformation
      },
      {
        path: "/management/life_cycle_stages",
        name: "Life Cycle",
        icon: Settings,
        component: DeviceLifeCycleStages
      },
      {
        path: "/management/device_types",
        name: "Device Types",
        icon: Settings,
        component: DeviceTypes
      },
      {
        path: "/management/subscriptions",
        name: "Subscriptions",
        icon: Settings,
        component: Subscriptions
      },
      {
        path: "/management/ssh_credentials",
        name: "SSH Credentials",
        icon: Settings,
        component: DeviceDeployment
      },
      {
        path: "/management/add",
        name: "Add Device",
        icon: Settings,
        component: AddDevice
      },
    ]
  },
  {
    collapse: true,
    path: "/nimbus",
    name: 'Nimbus',
    state: "openNimbusManagement",
    roles: ["system_admin"],
    icon: Settings,
    views: [
      {
        path: "/nimbus/continental",
        name: "Continental",
        icon: Settings,
        component: Continental
      },
    ]
  },
  {
    path: "/profile/account",
    name: "Account",
    icon: AccountCircle,
    component: Account,
    profile: true,
  }
];

export default dashRoutes;
