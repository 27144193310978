import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import loaderStyle from "./loaderStyle";
import CircularProgress from "@mui/material/CircularProgress";
import {styled} from "@mui/material";


const OctLoader = styled(CircularProgress)(({theme}) => ({
  width: 300,
  color: "#668813",
  margin: "auto",
  alignSelf: "center",
  justifySelf: "center"
}));

function Loader({...props}) {
  const {classes} = props
  return (
    <div className={classes.center}>
      <br/>
      <OctLoader/>
      <br/>
    </div>
  );
}

Loader.propTypes = {};

export default withStyles(loaderStyle)(Loader);
