import React from "react"
import {connect} from 'react-redux'
// @material-ui/core components
import {Brightness1TwoTone} from "@material-ui/icons";
import {dangerColor, primaryColor} from "../../assets/jss/material-dashboard-pro-react";

import MyDropdown from "../../components/MyDropdown";
import {deviceFleetActions} from "../../_actions";
import {get_sorted_devices} from "./sorting";
import queryString from "query-string";
import {history} from "../../index";


class DeviceDropdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handle_form_input_selector(name, value) {
    const {dispatch} = this.props
    dispatch(deviceFleetActions.updateSerial(value))
    const searchString = queryString.stringify({
      device_serial: value
    })

    history.push({
      search: searchString
    })
  }



  render() {
    const {state_received_devices, device_serial} = this.props
    let all_devices = get_sorted_devices()

    return (
      <MyDropdown
        input_label={"Device"}
        name={"device_serial"}
        value={device_serial}
        default_value={device_serial}
        handle_selection={
          this.handle_form_input_selector.bind(this)
        }
        options={[
          ...all_devices.map(function (item, key) {
            let this_device_online = dangerColor
            if (state_received_devices.includes(item.serial))
              this_device_online = primaryColor

            return {
              id: item.serial,
              value: <div>
                <Brightness1TwoTone fontSize={"small"} style={{
                  color: this_device_online,
                  marginBottom: "-5px"
                }}/> {item.name}
              </div>
            }

          })
        ]}
      />
    )
  }
}

function mapStateToProps(state) {
  const {all_devices, device_serial, device_state} = state.device_fleet

  let state_received_devices = Object.keys(device_state)
  return {
    state_received_devices, all_devices, device_serial, device_state
  }
}


export default connect(mapStateToProps)(DeviceDropdown)
