import SuperAgent from 'superagent'
import {api_root} from 'index.js'
import {errorHandling, unauthorizedRedirect} from './error.handler.js'
import {getBearerAccessToken} from "./authenticate";

export const managementService = {
  getAllDevices,
  getAllLifeCycleStages,
  getAllDeviceTypes,
  createDevice,
  updateDevice,
  updateDeviceType,
  deleteDeviceCredentials,
  generateDeviceCredentials,
};

function getAllDevices(callback, reject) {
  return SuperAgent
  .get(api_root + "/management/all_devices")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getAllLifeCycleStages(callback, reject) {
  return SuperAgent
  .get(api_root + "/management/all_life_cycle_stages")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getAllDeviceTypes(callback, reject) {
  return SuperAgent
  .get(api_root + "/management/all_device_types")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function updateDevice(device_serial, name, client_name, life_cycle_stage, metadata, callback, reject) {
  return SuperAgent
  .post(api_root + "/management/update_device/" + device_serial)
  .send({name})
  .send({client_name})
  .send({life_cycle_stage})
  .send({metadata})
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function updateDeviceType(device_type_id, name, callback, reject) {
  return SuperAgent
  .post(api_root + "/management/update_device_type/" + device_type_id)
  .send({name})
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function createDevice(device_serial, name, device_type, callback, reject) {
  return SuperAgent
  .post(api_root + "/management/create_device/" + device_serial)
  .send({name})
  .send({device_type})
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function deleteDeviceCredentials(device_serial, callback, reject) {
  return SuperAgent
  .get(api_root + "/management/delete_credentials/" + device_serial)
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function generateDeviceCredentials(device_serial, algorithm_type, callback, reject) {
  return SuperAgent
  .post(api_root + "/management/generate_credentials/" + device_serial)
  .send({algorithm_type})
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const FileSaver = require('file-saver');
    const blob = new Blob([response.text], {type: response.type});

    let file_name = device_serial + "_privkey.pem"
    if (algorithm_type === "IDENTITY") {
      file_name = device_serial + ".identity"
    }

    FileSaver.saveAs(blob, file_name)
    callback()
  })
  .catch(reject)
  .catch(errorHandling)
}