import React from "react"
import {connect} from 'react-redux'
import {filterSubstring} from "../helper_functions/table_filtering";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import Tooltip from "@material-ui/core/Tooltip"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Button from "components/CustomButtons"
import Card from "components/Card"
import CardBody from "components/CardBody"
import CardHeader from "components/CardHeader"

import extendedTablesStyle from "./extendedTablesStyle.jsx"

import {beautify_name, lumion_fix_translate_state} from "../helpers";
import {Brightness1TwoTone, Close, Info, Wifi} from "@material-ui/icons";
import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import Sunny from "@material-ui/icons/WbSunnyTwoTone";
import {Link} from "react-router-dom";
import MyReactTable from "../../components/MyReactTable/MyReactTable";

import ReactTimeAgo from 'react-time-ago'
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import queryString from "query-string";
import ClipboardButton from "../../components/ClipboardButoon";
import {get_battery_icon, get_connection_icon} from "../helper_functions/device_icons";


class StateFleet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      state_overview: [],
      state_page: 0,
      last_updated: null,
      filter_device_serial: null,
      filter_error: false,
      filter_operational: false,
    }

    this.timer = null
    this._is_mounted = false
    this._refresh_rate = 3000
  }

  on_page_change(new_page) {
    this.setState({state_page: new_page})
  }

  componentDidMount() {
    this._is_mounted = true
    const {filter_device_serial} = queryString.parse(this.props.location.search)
    let my_device_serial_filteral = null

    if (filter_device_serial !== undefined && filter_device_serial !== null) {
      my_device_serial_filteral = filter_device_serial.split(";")
    }
    this.setState({
      filter_device_serial: my_device_serial_filteral
    }, () => {
      this.refresh()
    })
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this._is_mounted = false
  }

  refresh() {
  }

  clear_filters() {
    this.setState({
      filter_device_serial: null,
      filter_error: false,
      filter_operational: false,
    }, () => this.refresh())
  }

  filter_error() {
    this.setState({
      filter_error: true,
      filter_operational: false,
      filter_device_serial: null,
    }, () => this.refresh())
  }

  filter_operational() {
    this.setState({
      filter_operational: true,
      filter_error: false,
      filter_device_serial: null,
    }, () => this.refresh())
  }

  render() {
    const {
      classes,
      error_codes,
      locations,
      all_commissioned_devices,
      device_state,
      all_devices,
      deployments
    } = this.props
    const {
      state_page,
      last_updated,
    } = this.state
    const {filter_device_serial, filter_error, filter_operational} = this.state

    let data = all_commissioned_devices.filter(function (device) {
      if (filter_device_serial !== null) {
        return filter_device_serial.indexOf(device.serial) > -1
      }
      let error_code = 0
        if (device.device_type === "LUMION_FIX" && device_state && device_state[device.serial]) {
          error_code = device_state[device.serial].state.error_code
        } else if (device.device_type === "DIV01" && device_state && device_state[device.serial]) {
          error_code = device_state[device.serial].state.error
        }
      if (filter_error) {
        return error_code !== 0;
      }
      if (filter_operational) {
        let device_info = {}
        all_devices.forEach((dev_) => {
          if (dev_.serial === device.serial) {
            device_info = dev_
          }
        })
        return device_info.life_cycle_stage === "OPERATIONAL"
      }
      return true
    }).map((device) => {
      return {
        serial: device.serial,
        name: device.name,
        device_type: device.device_type,
        is_live: Object.keys(device_state).includes(device.serial),  // FIXME: also discard the back offline devices
        last_seen: device_state && device_state[device.serial] ? device_state[device.serial].timestamp : null,
        has_wifi: null, // TODO: fix the value
        actions: <Link to={`/device/detail?device_serial=${device.serial}`}><Info
          fontSize="small"/> Info</Link>,
        actions_b: <Link to={`/device/commands?device_serial=${device.serial}`}><Sunny
          fontSize="small"/> Actions</Link>
      }
    })

    let columns = [
      {
        Header: <Wifi fontSize="small" style={{color: "rgb(173,170,170)"}}/>,
        accessor: "is_live",
        Cell: row => (
          get_connection_icon(classes, row.value, row.original.last_seen, row.original.has_wifi)
        ),
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }

          if (filter.value === "online") {
            return row["is_live"] === true;
          }

          if (filter.value === "offline") {
            return row["is_live"] === false;
          }

          return false;
        },

        Filter: ({filter, onChange}) =>
          <FormControl fullWidth className={classes.selectFormControl}>
            <Select
              onChange={event => {
                onChange(event.target.value)
              }}
              style={{width: "100%"}}
              value={filter ? filter.value : "all"}
            >
              <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                        value={'all'}>
                {"all"}
              </MenuItem>
              <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                        value={'online'}>
                <Brightness1TwoTone fontSize="small" style={{color: "rgb(127, 175, 12)"}}/>
              </MenuItem>
              <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                        value={'offline'}>
                <Brightness1TwoTone fontSize="small" style={{color: "rgb(175,12,12)"}}/>
              </MenuItem>
            </Select>
          </FormControl>,
        width: 50,
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: row => (
          <div><ClipboardButton text={row.original.serial}/><b>{row.value}</b></div>
        ),
        filterMethod: (filter, row) => filterSubstring(filter, row, "name")
      },
      {
        Header: "Location",
        accessor: "serial",
        filterMethod: (filter, row) => filterSubstring(filter, row),
        Cell: row => {
          let location_id = null
          Object.keys(deployments).forEach((deployment_id) => {
            if (deployments[deployment_id].device_serial === row.value) {
              location_id = deployments[deployment_id].location_id
            }
          })

          return locations && location_id && locations[location_id] ? locations[location_id].name : "Unknown"
        }
      },
      {
        Header: "Current Task",
        accessor: "serial",
        Cell: row => {
          let state_name = "-"
          let error_code = "-"
          if (row.original.device_type === "LUMION_FIX" && device_state && device_state[row.value]) {
            state_name = lumion_fix_translate_state(device_state[row.value].state.current_state, device_state[row.value].state.current_task)
            error_code = device_state[row.value].state.error_code
          } else if (row.original.device_type === "DIV01" && device_state && device_state[row.value]) {
            state_name = device_state[row.value].state.state
            error_code = device_state[row.value].state.error
          }

          let hexErrorCode = "0x" + error_code.toString(16).padStart(8, '0');

          if (!row.original.is_live) {
            return "-"
          }
          return state_name.toLowerCase() === 'error' ?
            <span
              style={{color: 'rgb(255,0,0)'}}>
                            {beautify_name(state_name)}
              <Tooltip
                id="tooltip2"
                title={error_codes[hexErrorCode] ? error_codes[hexErrorCode]["development"] : "unknown"}
                placement="right"
                classes={{tooltip: classes.tooltip}}
              >
                                <p>{hexErrorCode}</p>
                            </Tooltip>
                        </span> :
            state_name.toLowerCase() === "unknown" ? "-" :
              <p>{beautify_name(state_name)}</p>
        },
        filterMethod: (filter, row) => filterSubstring(filter, row)
      },
      {
        Header: "Battery",
        accessor: "serial",
        width: 120,
        filterable: false,
        Cell: row => {
          let battery_level = -1

          if (row.original.device_type === "LUMION_FIX" && device_state && device_state[row.value]) {
            battery_level = device_state[row.value].state.battery_level
          }

          return battery_level < 0 ? "-" : get_battery_icon(battery_level)
        }
      },
      {Header: "", accessor: "actions", sortable: false, filterable: false, width: 100},
      {Header: "", accessor: "actions_b", sortable: false, filterable: false, width: 100}
    ]

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              <GridContainer>
                <GridItem xs={6}>
                  <h4 style={{color: primaryColor}}>
                    <b>{"Overview All Devices"}</b>
                    {last_updated !== null ?
                      <p>Last update: <ReactTimeAgo date={last_updated} locale="en-UK"/>
                      </p> : ""}
                  </h4>

                </GridItem>
                <GridItem xs={6} style={{textAlign: "right"}}>
                  <Button simple
                          color={null == filter_device_serial && filter_error === false && filter_operational === false ? "gray" : "danger"}
                          onClick={this.clear_filters.bind(this)}
                          disabled={null == filter_device_serial && filter_error === false && filter_operational === false}>
                    <Close/> Clear Filter
                  </Button>
                  <Button simple color={"primary"} onClick={this.filter_operational.bind(this)}>
                    {filter_operational ? <b>Operational Devices</b> : <span>Operational Devices</span>}
                  </Button>
                  <Button simple color={"primary"} onClick={this.filter_error.bind(this)}>
                    {filter_error ? <b>Devices in Error</b> : <span>Devices in Error</span>}
                  </Button>
                </GridItem>

              </GridContainer>

            </CardHeader>
            <CardBody>
              <MyReactTable
                id={"state_overview"}
                data={data.sort((a, b) => {
                  return a["name"] < b["name"] ? -1 : 1
                })}
                filterable
                columns={columns}
                getTrProps={(state, rowInfo, column) => {
                  if (rowInfo && rowInfo.row) {
                    return {
                      style: {
                        background: rowInfo.row._original.last_seen === "" ? "rgba(255, 0, 0, 0.05)" : null
                      }
                    }
                  } else {
                    return {}
                  }
                }}
                defaultPageSize={10}
                page={state_page}
                onPageChange={this.on_page_change.bind(this)}
                showPaginationTop={false}
                showPaginationBottom={true}
                className=" -highlight"
                loading={false}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}


function mapStateToProps(state) {
  const {all_devices, device_state, all_commissioned_devices, device_data} = state.device_fleet
  const {error_codes} = state.device_fleet
  const {locations, deployments} = state.location
  return {
    error_codes, locations, all_devices, device_state, all_commissioned_devices, device_data, deployments
  }
}


export default withStyles(extendedTablesStyle)(connect(mapStateToProps)(StateFleet))
