import React from "react"
import {connect} from 'react-redux'
import {filterSubstring} from "../helper_functions/table_filtering";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Card from "components/Card"
import CardBody from "components/CardBody"
import CardHeader from "components/CardHeader"

import extendedTablesStyle from "./extendedTablesStyle.jsx"

import {deviceService, managementService} from "_services"
import {Brightness1TwoTone, CloseTwoTone, DoneTwoTone, Wifi,} from "@material-ui/icons";
import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import MyReactTable from "../../components/MyReactTable/MyReactTable";

import ReactTimeAgo from 'react-time-ago'
import {get_connection_icon} from "../helper_functions/device_icons";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "../../components/CustomButtons";
import {Alert} from "@mui/material";


class SettingsFleet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      settings_overview: [],
      settings_detail: {},
      settings_page: 0,
      last_updated: null,
      loading: false,
      all_device_types: {},
      all_life_cycle_stages: {},
      show_device_settings: true,
      show_software_info: false,
      show_map_info: false,
      show_sim_info: false,

      all_sim_states: {},
      all_sw_states: {},
      all_map_states: {},
    }

    this.timer = null
    this._is_mounted = false
    this._refresh_rate = 10000
  }

  on_page_change(new_page) {
    this.setState({settings_page: new_page})
  }

  componentDidMount() {
    this._is_mounted = true
    this.setState({})
    this.refresh()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this._is_mounted = false
  }

  refresh() {
    const {all_commissioned_devices} = this.props
    managementService.getAllLifeCycleStages(res => {
      let all_life_cycle_stages = {}
      res.result.forEach((life_cycle_stage) => {
        all_life_cycle_stages[life_cycle_stage.id] = life_cycle_stage.name
      })
      this.setState({
        all_life_cycle_stages,
      })
    })

    managementService.getAllDeviceTypes(res => {
      let all_device_types = {}
      res.result.forEach((device_type) => {
        all_device_types[device_type.id] = device_type.name
      })
      this.setState({
        all_device_types,
      })
    })

    if (all_commissioned_devices) {
      all_commissioned_devices.forEach((device) => {
        deviceService.getDeviceSwVersion(device.serial, res => {
          this.setState(prevState => {
            return {
              ...prevState,
              all_sw_states:
                {
                  ...prevState.all_sw_states,
                  [device.serial]: res.sw_version
                }
            }
          })
        })
        deviceService.getDeviceSimInfo(device.serial, res => {

          this.setState(prevState => {
            return {
              ...prevState,
              all_sim_states:
                {
                  ...prevState.all_sim_states,
                  [device.serial]: res && res.sim_info ? res.sim_info.sim_info : null
                }
            }
          })
        })
        deviceService.getDeviceMapData(device.serial, res => {
          let all_maps_valid = true
          Object.keys(res.available_maps).forEach(map_id => {
            let map = res.available_maps[map_id]
            if (!map["valid"]) {
              all_maps_valid = false
            }
          })

          this.setState(prevState => {
            return {
              ...prevState,
              all_map_states:
                {
                  ...prevState.all_map_states,
                  [device.serial]: all_maps_valid
                }
            }
          })
        })

      })
    }
    if (this._is_mounted) {
      this.timer = setTimeout(() => this.refresh(), this._refresh_rate)
    }
  }

  render() {
    const {classes, all_commissioned_devices, device_state} = this.props
    const {
      loading,
      settings_page,
      last_updated,
      show_device_settings,
      all_device_types,
      all_life_cycle_stages,
      show_software_info,
      show_map_info,
      show_sim_info,

      all_sim_states,
      all_sw_states,
      all_map_states,
    } = this.state

    let columns = [
      {
        Header: <Wifi fontSize="small" style={{color: "rgb(173,170,170)"}}/>,
        accessor: "serial",
        Cell: row => {
          let is_live = device_state && Object.keys(device_state).includes(row.value)
          let last_seen = device_state && device_state[row.value] ? device_state[row.value].timestamp : null
          return (
            get_connection_icon(classes, is_live, last_seen, null)
          )
        },
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }
          let is_live = device_state && Object.keys(device_state).includes(row.serial)

          if (filter.value === "online") {
            return is_live === true;
          }

          if (filter.value === "offline") {
            return is_live === false;
          }

          return false;
        },

        Filter: ({filter, onChange}) =>
          <FormControl fullWidth className={classes.selectFormControl}>
            <Select
              onChange={event => onChange(event.target.value)}
              style={{width: "100%"}}
              value={filter ? filter.value : "all"}
            >
              <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                        value={'all'}>
                {"all"}
              </MenuItem>
              <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                        value={'online'}>
                <Brightness1TwoTone fontSize="small" style={{color: "rgb(127, 175, 12)"}}/>
              </MenuItem>
              <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                        value={'offline'}>
                <Brightness1TwoTone fontSize="small" style={{color: "rgb(175,12,12)"}}/>
              </MenuItem>
            </Select>
          </FormControl>,
        width: 50,
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: row => (
          <b>{row.value}</b>
        ),
        filterMethod: (filter, row) => filterSubstring(filter, row)
      },
      {
        Header: "Last Sent",
        accessor: "serial",
        Cell: (row) => {
          let last_seen = device_state && device_state[row.value] ? device_state[row.value].timestamp : null
          if (!last_seen) {
            return "Unknown"
          }
          return <ReactTimeAgo date={last_seen} locale="en-UK"/>
        },
        filterable: false
      }
    ]

    if (show_device_settings) {
      columns.push(
        ...[
          {
            Header: "Serial Number",
            accessor: "serial",
            filterMethod: (filter, row) => filterSubstring(filter, row)
          },
          {
            Header: "Device Type",
            accessor: "device_type",
            Cell: row => {
              return (
                all_device_types[row.value]
              )
            },
            filterMethod: (filter, row) => filterSubstring(filter, row)
          },
          {
            Header: "Life Cycle Stage",
            accessor: "life_cycle_stage",
            Cell: row => {
              return (
                all_life_cycle_stages[row.value]
              )
            },
            filterMethod: (filter, row) => filterSubstring(filter, row)
          }]
      )
    }

    if (show_software_info) {
      columns.push(...[
        {
          Header: "Software Version",
          accessor: "serial",
          Cell: (row) => {
            if (all_sw_states && all_sw_states[row.value] && all_sw_states[row.value].software_version) {
              return all_sw_states[row.value].software_version
            }
            return "-"
          },
          filterMethod: (filter, row) => filterSubstring(filter, row)
        }]
      )
    }

    if (show_map_info) {
      columns.push(...[
        {
          Header: "Maps Valid?",
          accessor: "serial",
          width: 120,
          sortable: false,
          filterable: false,
          Cell: row => {
            let all_maps_value = null

            if (all_map_states) {
              all_maps_value = all_map_states[row.value]
            }

            if (all_maps_value !== true && all_maps_value !== false) {
              return "-"
            }

            return <span style={{color: all_maps_value ? "rgb(100,169,68)" : "rgb(231,92,92)"}}>
                        {
                          all_maps_value ? <DoneTwoTone/> : <CloseTwoTone/>
                        }
                    </span>

          },
          filterMethod: (filter, row) => {
            if (filter.value === "all") {
              return true;
            }

            let all_maps_value = null

            if (all_map_states) {
              all_maps_value = all_map_states[row.serial]
            }

            if (filter.value === "unknown") {
              return all_maps_value !== true && all_maps_value !== false;
            }

            if (filter.value === "valid") {
              return all_maps_value === true;
            }

            if (filter.value === "invalid") {
              return all_maps_value === false;
            }

            return false;
          },

          Filter: ({filter, onChange}) =>
            <FormControl fullWidth className={classes.selectFormControl}>
              <Select
                onChange={event => onChange(event.target.value)}
                style={{width: "100%"}}
                value={filter ? filter.value : "all"}
              >
                <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                          value={'all'}>
                  {"all"}
                </MenuItem>
                <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                          value={'unknown'}>
                  {"unknown"}
                </MenuItem>
                <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                          value={'valid'}>
                                <span style={{color: "rgb(100,169,68)"}}>
                        <DoneTwoTone/>
                    </span>
                </MenuItem>
                <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                          value={'invalid'}>
                               <span style={{color: "rgb(231,92,92)"}}>
                        <CloseTwoTone/>
                    </span>
                </MenuItem>
              </Select>
            </FormControl>,
        }
      ])
    }

    if (show_sim_info) {
      columns.push(
        ...[
          {
            Header: "Sim Customer",
            accessor: "serial",
            Cell: row => {
              let sim_info = {}
              if (all_sim_states && all_sim_states[row.value]) {
                sim_info = all_sim_states[row.value]
              }

              if (!sim_info || sim_info === {} || !sim_info.customer) {
                return "-"
              }

              return sim_info.customer
            },
          },
          {
            Header: "Rate Plan",
            accessor: "serial",
            width: 180,
            Cell: row => {

              let sim_info = {}
              if (all_sim_states && all_sim_states[row.value]) {
                sim_info = all_sim_states[row.value]
              }

              if (!sim_info || sim_info === {} || !sim_info.rate_plan) {
                return "-"
              }

              return showWarning(sim_info.rate_plan, sim_info.rate_plan.indexOf("Keep Alive") > -1)
            },
          },
          {
            Header: "Limit Reached",
            accessor: "serial",
            Cell: row => {

              let sim_info = {}
              if (all_sim_states && all_sim_states[row.value]) {
                sim_info = all_sim_states[row.value]
              }

              if (!sim_info || sim_info === {} || !sim_info.overage_limit_reached) {
                return "-"
              }
              return sim_info.overage_limit_reached ? "Yes" : "No"
            },
          },
          {
            Header: "Limit Override",
            accessor: "serial",
            Cell: row => {

              let sim_info = {}
              if (all_sim_states && all_sim_states[row.value]) {
                sim_info = all_sim_states[row.value]
              }

              if (!sim_info || sim_info === {} || !sim_info.overage_limit_override) {
                return "-"
              }
              return sim_info.overage_limit_override
            },
          }
        ]
      )
    }

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader><h4 style={{color: primaryColor}}>
              <b>{"Overview All Devices"}</b> {last_updated !== null ?
              <p>Last update: <ReactTimeAgo date={last_updated} locale="en-UK"/></p> : ""}</h4>
            </CardHeader>
            <CardBody>
              <Button
                onClick={() => this.setState(prevState => ({
                  prevState,
                  show_device_settings: !prevState.show_device_settings
                }))}
                color={show_device_settings ? "primary" : "github"}
                simple
              >
                Show Device Info
              </Button>
              <Button
                onClick={() => this.setState(prevState => ({
                  prevState,
                  show_software_info: !prevState.show_software_info
                }))}
                color={show_software_info ? "primary" : "github"}
                simple
              >
                Show Software Info
              </Button>
              <Button
                onClick={() => this.setState(prevState => ({
                  prevState,
                  show_map_info: !prevState.show_map_info
                }))}
                color={show_map_info ? "primary" : "github"}
                simple
              >
                Show Map Info
              </Button>
              <Button
                onClick={() => this.setState(prevState => ({
                  prevState,
                  show_sim_info: !prevState.show_sim_info
                }))}
                color={show_sim_info ? "primary" : "github"}
                simple
              >
                Show SIM Info
              </Button>
              <MyReactTable
                id={"settings_overview"}
                data={all_commissioned_devices ? all_commissioned_devices.sort((a, b) => {
                  return a["name"] < b["name"] ? -1 : 1
                }) : []}
                filterable
                columns={columns}
                getTrProps={(state, rowInfo, column) => {
                  if (rowInfo && rowInfo.row) {
                    return {
                      style: {
                        background: rowInfo.row._original.last_seen === "" ? "rgba(255, 0, 0, 0.05)" : null
                      }
                    }
                  } else {
                    return {}
                  }
                }}
                defaultPageSize={10}
                page={settings_page}
                onPageChange={this.on_page_change.bind(this)}
                showPaginationTop={false}
                showPaginationBottom={true}
                className=" -highlight"
                loading={loading}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}


function mapStateToProps(state) {
  const {all_devices, all_commissioned_devices, device_state} = state.device_fleet
  return {
    all_devices, all_commissioned_devices, device_state
  }
}

function showWarning(text, show) {
  return (show ? <Alert severity={"warning"}> {text} </Alert> : {text})
}

export default withStyles(extendedTablesStyle)(connect(mapStateToProps)(SettingsFleet))
