import React, {useState} from 'react';
import {connect} from 'react-redux'
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Button from "components/CustomButtons"
import Card from "components/Card"
import CardBody from "components/CardBody"
import CardHeader from "components/CardHeader"

import {incidentService} from "_services"
import Info from "@material-ui/icons/InfoTwoTone";
import {dangerColor, primaryColor} from "../../assets/jss/material-dashboard-pro-react";

import ReactTimeAgo from 'react-time-ago'
import queryString from "query-string";
import ReactTable from "../../components/react-table";
import MyDropdown from "../../components/MyDropdown";
import CustomInput from "../../components/CustomInput";
import IncidentDetail from "./IncidentDetail";

import extendedTablesStyle from "./extendedTablesStyle.jsx"

import Copy from "@material-ui/icons/FileCopyTwoTone";
import {api_root} from "../../index";
import Tooltip from "@material-ui/core/Tooltip";
import {store} from "_helpers/store";
import {getBearerAccessToken} from "../../_services/authenticate";


function ClipboardButton({text}) {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(text);
  };

  return (
    <Button simple color={"primary"} onClick={copyToClipboard}>
      <Copy/> Copy Link
    </Button>
  );
}

const VideoComponent = ({incident_id}) => {
  const [videoUrl, setVideoUrl] = useState(null);
  const [videoFound, setVideoFound] = useState(true);
  const [prevID, setPrevID] = useState("");

  const fetchVideo = async () => {
    setVideoUrl(null);
    setVideoFound(true);
    setPrevID(incident_id)
    const state = store.getState()
    const response = await fetch(api_root + "/incidents/video/" + incident_id, {
      headers: {
        'Authorization': getBearerAccessToken()
      }
    });
    const videoData = await response.blob();
    if (videoData.size > 40) {
      const blobUrl = URL.createObjectURL(videoData);
      setVideoUrl(blobUrl);
    } else {
      setVideoFound(false)
    }
  };

  if (incident_id !== prevID) {
    fetchVideo();
  }

  return (
    <>
      {videoUrl ? (
        <video width={"150px"} controls>
          <source src={videoUrl} type="video/mp4"/>
        </video>
      ) : (
        videoFound ? (
          <p style={{minWidth: "150px"}}>Loading video...</p>
        ) : (
          <p style={{minWidth: "150px", color: dangerColor}}>Video not found</p>
        )
      )}
    </>
  );
};


class Incidents extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      totalResults: 0,
      totalPages: 0,
      pageSize: 10,
      currentPage: 1,
      detail_view_incident: undefined,
      last_updated: null,
      loading: false,
      data: [],
      device_serial: undefined,
      treatment_id: undefined,
      error_code: undefined,
      map_id: undefined,
      selected_incident_id: undefined
    }

    this.timer = null
    this._is_mounted = false
    this._refresh_rate = 3000
  }

  componentDidMount() {
    this._is_mounted = true

    let my_device_serial = undefined
    let my_incident_id = undefined
    let my_treatment_id = undefined
    const {device_serial} = queryString.parse(this.props.location.search)
    const {incident_id} = queryString.parse(this.props.location.search)
    const {treatment_id} = queryString.parse(this.props.location.search)

    if (device_serial !== undefined && device_serial !== null) {
      my_device_serial = device_serial
    }

    if (incident_id !== undefined && incident_id !== null) {
      my_incident_id = incident_id
    }

    if (treatment_id !== undefined && treatment_id !== null) {
      my_treatment_id = treatment_id
    }

    this.setState({
      detail_view_incident: my_incident_id,
      treatment_id: my_treatment_id,
      device_serial: my_device_serial
    }, () => this.refresh())

  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this._is_mounted = false
  }

  refresh() {
    const {device_serial, map_id, treatment_id, error_code, pageSize, currentPage} = this.state
    this.setState({loading: true})
    incidentService.getAll(
      device_serial,
      map_id,
      treatment_id,
      error_code,
      pageSize,
      currentPage - 1,
      result => {
        const total_pages = Math.ceil(result.total / pageSize)
        let newCurrentPage = currentPage
        if (currentPage > total_pages && result.total > 0) {
          newCurrentPage = total_pages
        }

        let data = result.incidents.map((incident) => {
          return {
            ...incident,
            actions: <div><Button
              simple
              color={'primary'}
              onClick={() => this.to_detailed_view(incident["incident_id"])}
            >
              <Info/>
              Info
            </Button>
              <ClipboardButton
                text={window.location.href.includes(incident["incident_id"])
                  ?
                  window.location.href :
                  `${window.location.href}?incident_id=${incident["incident_id"]}`
                }
              />
            </div>,
          }
        })

        this.setState({
          totalResults: result.total,
          currentPage: newCurrentPage,
          data: data,
          totalPages: total_pages,
          loading: false,
        })
      }, error => {
        this.setState({
          data: [],
          totalPages: 0,
          loading: false
        })
      });

  }

  to_detailed_view(incident_id) {
    this.setState({detail_view_incident: incident_id}, () => this.refresh())
  }

  back_to_overview() {
    this.setState({detail_view_incident: undefined}, () => this.refresh())
  }

  handle_form_input_selector_device_serial(name, value) {
    this.setState({
      device_serial: value,
      map_id: undefined,
      treatment_id: undefined,
      error_code: undefined
    }, () => this.refresh())
  }

  handle_form_input_selector_error_code(name, value) {
    this.setState({
      device_serial: undefined,
      map_id: undefined,
      treatment_id: undefined,
      error_code: value
    }, () => this.refresh())
  }

  handle_form_input_selector_map_id(name, value) {
    this.setState({
      map_id: value,
      device_serial: undefined,
      treatment_id: undefined,
      error_code: undefined
    }, () => this.refresh())
  }

  render() {
    const {error_codes, maps, classes, all_commissioned_devices, all_devices} = this.props
    const {
      detail_view_incident,
      last_updated,
      selected_incident_id,
      treatment_id,
      loading,
      currentPage,
      pageSize,
      data,
      totalPages,
      totalResults
    } = this.state

    let filler_size = (currentPage - 1) * pageSize
    if (filler_size < 0) {
      filler_size = 0
    }
    let filler_array = Array(filler_size).keys()

    let columns =
      [
        {
          Header: <p style={{minWidth: "165px"}}>Video</p>,
          accessor: "incident_id",
          Cell: row => {
            return <VideoComponent incident_id={row.value}/>
          },
          minWidth: "165px"
        },
        {
          Header: "Device",
          accessor: "machine_serial",
          Cell: row => {

            let machine_name = row.value
            all_devices.forEach((device)=>{
              if (device.serial === row.value){
                machine_name = device.name
              }
            })

            return machine_name
          },
          filterable: true,
          Filter: ({filter, onChange}) =>
            <MyDropdown
              input_label={"Device Serial"}
              name={"device_serial"}
              value={this.state["device_serial"]}
              default_value={this.state["device_serial"]}
              options={[
                {id: undefined, value: "All"},
                ...all_commissioned_devices.map((item) => {
                  return {id: item.serial, value: item.name}
                })]}
              handle_selection={this.handle_form_input_selector_device_serial.bind(this)}
            />
        },
        {
          Header: "Error Time",
          accessor: "incident_time",
          Cell: row => {
            return new Date(row.value).toLocaleString("nl-BE")
          },
        },
        {
          Header: "Error Code",
          accessor: "error_code",
          Cell: row => {
            if (row.value === "Manually Triggered") {
              return row.value
            }

            return <span style={{color: 'rgb(255,0,0)'}}>
                     <Tooltip
                       id="tooltip2"
                       title={error_codes[row.value] ? <span
                         style={{fontSize: "0.9rem"}}>{error_codes[row.value]["development"]}</span> : "Unknown"}
                       placement="right"
                       classes={{tooltip: classes.tooltip}}
                     >
                         <div>{row.value} <Info fontSize={"small"}/></div>
                     </Tooltip>
                </span>
          },
          filterable: true,
          Filter: ({filter, onChange}) =>
            <MyDropdown
              input_label={"Error Code"}
              name={"error_code"}
              value={this.state["error_code"]}
              default_value={this.state["error_code"]}
              options={[
                {id: undefined, value: "All"},
                ...Object.keys(error_codes).map((item) => {
                  return {id: item, value: item + ": " + error_codes[item]["development"]}
                })]}
              handle_selection={this.handle_form_input_selector_error_code.bind(this)}
            />
        },
        {
          Header: "Map Information",
          accessor: "map_id",
          Cell: row => {
            return <div> {maps && maps[row.value] ? maps[row.value]["name"] : row.value} <br/>({row.original.node_id})
            </div>
          },
          filterable: true,
          Filter: ({filter, onChange}) =>
            <MyDropdown
              input_label={"Map"}
              name={"map_id"}
              value={this.state["map_id"]}
              default_value={this.state["map_id"]}
              options={[
                {id: undefined, value: "All"},
                ...Object.keys(maps).map((map_id) => {
                  return {id: map_id, value: maps[map_id] ? maps[map_id]["name"] : map_id}
                })]}
              handle_selection={this.handle_form_input_selector_map_id.bind(this)}
            />
        },
        {
          Header: "Incident ID",
          accessor: "incident_id",
        },
        {
          Header: "Treatment ID",
          accessor: "treatment_id",
          filterable: true,
          Filter: ({filter, onChange}) =>
            <CustomInput
              style={{
                fullWidth: true,
                width: "100%"
              }}
              id={"treatment_id"}
              labelText={"Treatment ID"}
              inputProps={{
                onChange: (event) => {
                  this.setState({
                    "treatment_id": event.target.value === "" ? undefined : event.target.value,
                    "map_id": undefined,
                    "device_serial": undefined,
                    "error_code": undefined
                  }, () => this.refresh())
                },
                type: "text",
                fullWidth: true,
                value: treatment_id
              }}
            />
        },
        {Header: "", accessor: "actions", sortable: false, filterable: false, width: 100},
      ]

    return (
      detail_view_incident === undefined ? (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader><h4 style={{color: primaryColor}}>
                  <b>{"Overview All Incidents"}</b> {last_updated !== null ?
                  <p>Last update: <ReactTimeAgo date={last_updated} locale="en-UK"/></p> : ""}</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={6} style={{width: "100%", fullWidth: true}}>
                      <GridContainer style={{width: "100%", fullWidth: true}}>
                        <GridItem xs={8} style={{width: "100%", fullWidth: true}}>
                          <CustomInput
                            style={{
                              fullWidth: true,
                              width: "100%"
                            }}
                            id={"selected_incident_id"}
                            labelText={"Incident ID"}
                            fullWidth={true}
                            inputProps={{
                              onChange: (event) => {
                                this.setState({
                                  "selected_incident_id": event.target.value === "" ? undefined : event.target.value
                                })
                              },
                              type: "text",
                              fullWidth: true,
                              value: selected_incident_id
                            }}
                          />
                        </GridItem>
                        <GridItem xs={2}>
                          <Button
                            style={{marginTop: "25px"}}
                            simple
                            color={"primary"}
                            size={"sm"}
                            onClick={() => {
                              this.to_detailed_view(selected_incident_id)
                            }}
                          >
                            <Info/> Go To Incident
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12}>
                      <h5># Results: {totalResults}</h5>
                      <ReactTable
                        columns={columns}
                        data={[
                          ...filler_array,
                          ...data
                        ]}
                        sortable={false}
                        filterable={false}
                        loading={loading}
                        pages={totalPages}
                        page={currentPage - 1}
                        pageSize={pageSize}
                        onPageChange={(pageIndex) => this.setState({currentPage: pageIndex + 1}, () => this.refresh())}
                        onPageSizeChange={(newPageSize) => {
                          this.setState({currentPage: 1, pageSize: newPageSize}, () => this.refresh())
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        )
        :
        (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader>
                  <h4 style={{color: primaryColor}}>
                    <b>Incident Detail</b>
                    <ClipboardButton text={window.location.href.includes(detail_view_incident)
                      ?
                      window.location.href :
                      `${window.location.href}?incident_id=${detail_view_incident}`
                    }/>
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12}>
                      <Button color={'primary'}
                              onClick={this.back_to_overview.bind(this)}>BACK</Button>
                    </GridItem>
                    <GridItem xs={12}>
                      <IncidentDetail incident_id={detail_view_incident}/>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        )

    )
  }
}

function mapStateToProps(state) {
  const {error_codes, all_commissioned_devices, all_devices} = state.device_fleet
  const {maps} = state.location
  return {
    error_codes, maps, all_commissioned_devices, all_devices
  }
}

export default withStyles(extendedTablesStyle)(connect(mapStateToProps)(Incidents))
