import React from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Card from "components/Card"
import CardBody from "components/CardBody"

// Assets
import userProfileStyles from "./userProfileStyles.jsx"

// Services
import {deviceService} from '_services'
import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";

import {Alert} from "@mui/material";
import Button from "../../components/CustomButtons";
import {SignalCellularAlt, Wifi} from "@material-ui/icons";

import NextIcon from "@material-ui/icons/NavigateNextTwoTone";
import PrevIcon from "@material-ui/icons/NavigateBeforeTwoTone";
import CustomInput from "../../components/CustomInput";
import Tooltip from "@material-ui/core/Tooltip";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import DeviceDropdown from "../helper_functions/DeviceDropdown";
import {deviceFleetActions} from "../../_actions";
import {get_sorted_devices} from "../helper_functions/sorting";
import queryString from "query-string";
import {history} from "../../index";
import {getUserId} from "../../_services/authenticate";


class DeviceSelector extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      is_live: false,
      has_wifi: false,
      in_control :{
        in_control: false,
        controller: "Unknown"
      }
    }
    this._is_mounted = false
    this.timer = null
    this._refresh_rate = 5000
  }

  keyDownHandler = ({key}) => {
    const {disable_key_bindings} = this.props

    if (disable_key_bindings)
      return

    if (key === "ArrowRight") {
      this.navigate(true, false)
    }

    if (key === "ArrowLeft") {
      this.navigate(false, true)
    }
  }

  componentDidMount() {
    this._is_mounted = true
    window.addEventListener("keydown", this.keyDownHandler);
  }

  take_control() {
    const {device_serial} = this.props

    deviceService.waitOnEvent(device_serial, "control", res => {
    })

    deviceService.takeControl(device_serial, res => {
      setTimeout(this.refresh.bind(this), 1000)
    })
  }

  release_control() {
    const {device_serial} = this.props
    deviceService.releaseControl(device_serial, res => {
      setTimeout(this.refresh.bind(this), 1000)
    })
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.keyDownHandler);

    this._is_mounted = false
    clearTimeout(this.timer)
    this.timer = null
  }

  handle_change(value) {
    const {dispatch} = this.props
    let sorted_devices = get_sorted_devices()
    let filtered_list = sorted_devices.filter(items => items.name.toLowerCase().indexOf(value.toLowerCase()) !== -1)
    if (filtered_list.length > 0) {
      dispatch(deviceFleetActions.updateSerial(filtered_list[0].serial))
      const searchString = queryString.stringify({
        device_serial: filtered_list[0].serial
      })

      history.push({
        search: searchString
      })
    }

  }

  refresh() {
    const {refresh, device_serial} = this.props
    deviceService.getDeviceStateData(device_serial, res => {
      this.setState({
        has_wifi: res["has_wifi/has_wifi"],
      })
    })

    deviceService.getDeviceRawState(device_serial, res => {
      let state = res.state
      this.setState({
        is_live: state.is_live,
        in_control : {
          in_control: state.state.controller === getUserId(),
          controller: state.state.controller
        }
      })
    })

    if (refresh) {
      refresh()
    }

    if (this._is_mounted){
      setTimeout(this.refresh.bind(this), 1000)
    }
  }

  navigate(next, previous) {
    const {dispatch, device_serial} = this.props
    let sorted_devices = get_sorted_devices()
    let current_device_index = sorted_devices.findIndex(item => item.serial === device_serial);

    if (next) {
      let next_item = sorted_devices[current_device_index + 1]
      if (next_item !== undefined) {
        dispatch(deviceFleetActions.updateSerial(next_item.serial))
        const searchString = queryString.stringify({
          device_serial: next_item.serial
        })

        history.push({
          search: searchString
        })
      }
    }

    if (previous) {
      let previous_item = sorted_devices[current_device_index - 1]
      if (previous_item !== undefined) {
        dispatch(deviceFleetActions.updateSerial(previous_item.serial))
        const searchString = queryString.stringify({
          device_serial: previous_item.serial
        })

        history.push({
          search: searchString
        })
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.device_serial !== this.props.device_serial) {
      this.refresh(this.props.device_serial)
    }
    if (prevProps && prevProps.device_state && (prevProps.device_state[prevProps.device_serial] !== this.props.device_state[this.props.device_serial])) {
      this.refresh_websocket_state(this.props.device_state[this.props.device_serial])
    }
    if (prevProps && prevProps.device_data && (prevProps.device_data[prevProps.device_serial] !== this.props.device_data[this.props.device_serial])) {
      this.refresh_websocket_data(this.props.device_data[this.props.device_serial])
    }
  }

  refresh_websocket_data(websocket_data) {
    let state_to_update = {}

    if ("has_wifi" in websocket_data) {
      state_to_update["has_wifi"] = websocket_data["has_wifi"]["has_wifi"]
    }

    this.setState(state_to_update)
  }

  refresh_websocket_state(websocket_state) {
    this.setState({
      is_live: true,
      in_control : {
        in_control: websocket_state.state.controller === getUserId(),
        controller: websocket_state.state.controller
      }
    })
  }

  render() {
    const {classes} = this.props
    const {is_live, has_wifi, in_control} = this.state

    return (
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem xs={10}>
              <h4 style={{color: primaryColor}}><b>{"Device"}</b></h4>
            </GridItem>
            <GridItem xs={2} style={{textAlign: "right"}}>
              <Link to={`/fleet/state`}>Back To Overview </Link>
            </GridItem>
            <GridItem xs={6} md={5}>
              <CustomInput
                labelText={"Search Device Name"}
                fullWidth={true}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: event => this.handle_change(event.target.value),
                  type: "text",
                  fullWidth: true
                }}
              />
            </GridItem>
            <GridItem xs={5}>
              <DeviceDropdown/>
            </GridItem>
            <GridItem xs={6} md={2}>
              <Tooltip
                id="tooltip2"
                title={"TIP: You can use your arrow keys to navigate through the devices"}
                placement="top"
                classes={{tooltip: classes.tooltip}}
              >
                <div>
                  <Button simple justIcon color={"primary"}
                          onClick={this.navigate.bind(this, false, true)}>
                    <PrevIcon/>
                  </Button>
                  <Button simple justIcon color={"primary"}
                          onClick={this.navigate.bind(this, true, false)}>
                    <NextIcon/>
                  </Button>
                </div>
              </Tooltip>
            </GridItem>
            <GridItem xs={12} md={4}>
              {!is_live && <Alert severity={"warning"}> Device is not online</Alert>}
              {is_live && <Alert icon={has_wifi ?
                <Wifi fontSize="small" style={{color: "rgb(127, 175, 12)"}}/>
                :
                <SignalCellularAlt fontSize="small" style={{color: "rgb(127, 175, 12)"}}/>
              } severity={"success"}> Device is online</Alert>}
            </GridItem>
            <GridItem xs={12} md={4}>
              {!in_control.in_control &&
                <Alert severity={"warning"}> You are not in control, in control
                  now: <b>{in_control.controller ? in_control.controller : "Nobody"}</b></Alert>}
              {in_control.in_control &&
                <Alert severity={"success"}> You are in control</Alert>}
            </GridItem>
            <GridItem xs={12} md={4}>
              <Button color={"transparent"} onClick={this.take_control.bind(this)}> Request
                Control </Button>
              <Button color={"transparent"} onClick={this.release_control.bind(this)}> Release
                Control </Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    )
  }
}

function mapStateToProps(state) {
  const {all_commissioned_devices, device_serial, device_state} = state.device_fleet

  return {
    all_commissioned_devices, device_serial, device_state
  }
}


DeviceSelector.defaultProps = {
  disable_key_bindings: false,
}

export default withStyles(userProfileStyles)(connect(mapStateToProps)(DeviceSelector))
