import SuperAgent from 'superagent'
import {api_root} from 'index.js'
import {errorHandling, unauthorizedRedirect} from './error.handler.js'
import {getBearerAccessToken} from "./authenticate";

export const mapService = {
  getAllMaps,
  getContinentalMaps,
  getMapVersions,
  getMapDetail,
  exportGeoJson,
  getMapSyncInfo,
  getLocations,
  createLocation,
  updateLocation,
  getGreenhouses,
  createGreenhouse,
  updateGreenhouse,
  getNetworkInformation,
  updateNetworkInformation,
  getGreenhousesForLocation,
  getGreenhouseByMapID,
  getOrganisations,
  getOrganisation,
  createOrganisation,
  updateOrganisation,
  uploadNewMap,
  syncMap,
  getMapIds,
};


function updateNetworkInformation(location_id, wifi_ssid, wifi_password, callback, reject) {
  return SuperAgent
  .post(api_root + "/locations/" + location_id + "/update_network_information")
  .send({wifi_ssid})
  .send({wifi_password})
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}


function getNetworkInformation(location_id, callback, reject) {
  return SuperAgent
  .get(api_root + "/locations/" + location_id + "/get_network_information")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}


function createLocation(name, address, owned_by, callback, reject) {
  return SuperAgent
  .post(api_root + "/locations/create_location")
  .send({name})
  .send({address})
  .send({owned_by})
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}


function updateLocation(location_id, name, client_name, owned_by, address, callback, reject) {
  return SuperAgent
  .post(api_root + "/locations/" + location_id + "/update_location")
  .send({name})
  .send({client_name})
  .send({address})
  .send({owned_by})
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getLocations(per_page, page, callback, reject) {
  return SuperAgent
  .get(api_root + "/locations/get_locations")
  .query({per_page})
  .query({page})
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}


function createOrganisation(name, client_name, callback, reject) {
  return SuperAgent
  .post(api_root + "/locations/create_organisation")
  .send({name})
  .send({client_name})
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}


function updateOrganisation(organisation_id, name, client_name, callback, reject) {
  return SuperAgent
  .post(api_root + "/locations/" + organisation_id + "/update_organisation")
  .send({name})
  .send({client_name})
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getOrganisations(per_page, page, callback, reject) {
  return SuperAgent
  .get(api_root + "/locations/get_organisations")
  .query({per_page})
  .query({page})
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getOrganisation(organisation_id, callback, reject) {
  return SuperAgent
  .get(api_root + "/locations/get_organisation/" + organisation_id)
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getGreenhousesForLocation(location_id, callback, reject) {
  return SuperAgent
  .get(api_root + "/locations/" + location_id + "/get_greenhouses")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getGreenhouseByMapID(map_id, callback, reject) {
  return SuperAgent
  .get(api_root + "/locations/get_greenhouse_by_map/" + map_id)
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}


function createGreenhouse(name, location_id, map_id, timezone, callback, reject) {
  return SuperAgent
  .post(api_root + "/locations/create_greenhouse")
  .send({name})
  .send({location_id})
  .send({map_id})
  .send({timezone})
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}


function updateGreenhouse(greenhouse_id, name, map_id, timezone, location_id, callback, reject) {
  return SuperAgent
  .post(api_root + "/locations/" + greenhouse_id + "/update_greenhouse")
  .send({name})
  .send({map_id})
  .send({timezone})
  .send({location_id})
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getGreenhouses(per_page, page, callback, reject) {
  return SuperAgent
  .get(api_root + "/locations/get_greenhouses")
  .query({per_page})
  .query({page})
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}


function getAllMaps(per_page, page, callback, reject) {
  return SuperAgent
  .get(api_root + "/map/all_maps")
  .query({per_page})
  .query({page})
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}


function getContinentalMaps(per_page, page, callback, reject) {
  return SuperAgent
  .get(api_root + "/map/continental/list")
  .query({per_page})
  .query({page})
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}


function getMapVersions(map_id, callback, reject) {
  return SuperAgent
  .get(api_root + "/map/" + map_id + "/versions")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getMapIds(callback, reject) {
  return SuperAgent
  .get(api_root + "/map/maps/ids")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getMapDetail(map_id, callback, reject) {
  return SuperAgent
  .get(api_root + "/map/" + map_id + "/detail")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function exportGeoJson(map_id, map_version, gutter_version, obstacle_version, callback, reject) {
  return SuperAgent
  .get(api_root + "/map/" + map_id + "/" + map_version + "/geojson")
  .withCredentials()
  .query({gutter_version})
  .query({obstacle_version})
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getMapSyncInfo(map_id, map_version, callback, reject) {
  return SuperAgent
  .get(api_root + "/map/" + map_id + "/" + map_version + "/synced_info")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function uploadNewMap(map_id, map_version, zipped_map, callback, reject) {
  return SuperAgent
  .post(api_root + `/map/${map_id}/${map_version}/upload`)
  .attach("file", zipped_map, {})
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}


function syncMap(map_id, map_version, callback, reject) {
  return SuperAgent
  .get(api_root + `/map/${map_id}/${map_version}/sync`)
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}
