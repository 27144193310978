import SuperAgent from 'superagent'
import {api_root} from 'index.js'
import {errorHandling, unauthorizedRedirect} from './error.handler.js'
import {getBearerAccessToken} from "./authenticate";

export const nimbusService = {
  validateContinentalSync,
  getResultsValidationContinentalSync,
};

function validateContinentalSync(callback, reject) {
  return SuperAgent
  .get(api_root + "/nimbus/continental/validate")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getResultsValidationContinentalSync(per_page, page, validation_id, callback, reject) {
  return SuperAgent
  .get(api_root + "/nimbus/continental/results")
  .query({per_page})
  .query({page})
  .query({validation_id})
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}
