import React from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// Assets
import userProfileStyles from "./userProfileStyles.jsx"
import CardHeader from "../../components/CardHeader";
import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import GridContainer from "../../components/GridContainer";
import GridItem from "../../components/GridItem";
import Card from "../../components/Card";
import CardBody from "../../components/CardBody";
import {connect} from "react-redux";
import {Alert} from "@mui/material";

class Subscriptions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this._is_mounted = false
    this.timer = null
  }

  componentDidMount() {
    this._is_mounted = true
  }

  componentWillUnmount() {
    this._is_mounted = false
    clearTimeout(this.timer)
    this.timer = null
  }

  render() {

    return <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader><h4 style={{color: primaryColor}}><b> Subscriptions </b></h4></CardHeader>
          <CardBody>
            <Alert severity={"error"}> Not available at the moment</Alert>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>

  }
}


function mapStateToProps(state) {
  const {all_devices} = state.device_fleet
  return {
    all_devices
  }
}

export default withStyles(userProfileStyles)(connect(mapStateToProps)(Subscriptions))
