import React from "react"
import PropTypes from "prop-types";

import {FormControl, InputAdornment, withStyles} from "@material-ui/core";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import myDropdownStyle from "./myDropdownStyle";

class MyDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  handle_form_input_selector(event) {
    const {name} = this.props;
    this.setState({[name]: event.target.value})
    if (this.props.handle_selection !== undefined)
      this.props.handle_selection(name, event.target.value)
  }

  render() {
    const {classes, input_label, value, default_value, name, unit, options, disabled, multiple} = this.props;
    const fieldId = `ID_${name}`;

    return (
      <FormControl
        fullWidth
        className={classes.dropdownFormControl}
      >
        <TextField
          className={classes.dropdownSelectField}
          id={fieldId}
          select
          variant='standard'
          label={input_label}
          disabled={disabled}
          multiple={multiple}
          value={value}
          onChange={(event) => this.handle_form_input_selector(event)}
          defaultValue={default_value}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                className: classes.dropdownPaper
              }
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end' className={classes.dropdownEndAdornment}>
                <div>{unit}</div>
              </InputAdornment>
            ),
          }}
        >
          {options.map((item, key) => (
            <MenuItem
              key={key}
              value={item.id}
              className={classes.dropdownMenuItem}>
              {item.value}
            </MenuItem>
          ))}

        </TextField>
      </FormControl>
    )
  }
}

MyDropdown.propTypes = {
  classes: PropTypes.object.isRequired,
  input_label: PropTypes.string,
  value: PropTypes.any.isRequired,
  default_value: PropTypes.any,
  name: PropTypes.string.isRequired,
  unit: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      value: PropTypes.any.isRequired
    })
  ).isRequired,
  handle_selection: PropTypes.func
}

MyDropdown.defaultProps = {
  input_label: "",
  value: "",
  disabled: false
}

export default withStyles(myDropdownStyle)(MyDropdown)