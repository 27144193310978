import React from 'react'
import ReactDOM from 'react-dom'
import {CookiesProvider} from 'react-cookie'
import {Provider} from 'react-redux'
import {createBrowserHistory} from 'history'
import {store} from '_helpers/store'

import 'assets/scss/material-dashboard-pro-react.css?v=1.4.0'
import "react-vis/dist/style.css"

import registerServiceWorker from './registerServiceWorker'

import TimeAgo from 'javascript-time-ago'

import en from 'javascript-time-ago/locale/en.json'
import nl from 'javascript-time-ago/locale/nl.json'
import { AuthProvider } from 'react-oidc-context';
import App from './App'
TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(nl)

export const history = createBrowserHistory();
export var api_root = '/api';
if (process.env.NODE_ENV === "development") {
  api_root = 'http://' + window.location.hostname + ':5001/api';
}

function handleLogin(e) {
  console.log("logged in via external page", e)
}

const oidcConfig = {
  onSigninCallback: () => handleLogin,
  authority: `${window.REACT_APP_AUTHORITY}${window.REACT_APP_USER_POOL_ID}`,
  client_id: window.REACT_APP_CLIENT_ID,
  redirect_uri: window.REACT_APP_REDIRECT,
  response_type: 'code',
  scope: "openid email aws.cognito.signin.user.admin",
  silent_redirect_uri: window.REACT_APP_REDIRECT,
  automaticSilentRenew: true,
  loadUserInfo: true,
  extraHeaders: {'Access-Allow-Cross-Origin': '*'},
  showDebugInformation: true,
  post_logout_redirect_uri: window.REACT_APP_REDIRECT,
}


ReactDOM.render(
  <AuthProvider {...oidcConfig}>
    <CookiesProvider>
      <Provider store={store}>
        <App/>
      </Provider>
    </CookiesProvider>
  </AuthProvider>,
  document.getElementById('root')
);

registerServiceWorker();