import SuperAgent from 'superagent'
import {api_root} from 'index.js'
import {errorHandling, unauthorizedRedirect} from './error.handler.js'
import {getBearerAccessToken} from "./authenticate";

export const xenionService = {
  getAllErrorCodes,
  explainErrorCodes,
  updateErrorCode,
  registerErrorCode,
  getMaintenanceSettings,
  setMaintenanceSetting,
  getDeploymentInformation,
  updateDeploymentInformation,
  exportErrorCodes
};


function exportErrorCodes(language, downloaded_file_name, callback, revoke) {
  return SuperAgent
  .get(api_root + "/xenion/error_codes/export")
  .withCredentials()
  .query({language})
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .responseType('arraybuffer')
  .then((response) => {
    const FileSaver = require('file-saver');
    const blob = new Blob([response.body], {type: response.type});
    FileSaver.saveAs(blob, downloaded_file_name)
    callback()
  })
  .catch(errorHandling)
  .catch(revoke)
}


function explainErrorCodes(error_codes, callback, reject) {
  return SuperAgent
  .post(api_root + "/xenion/error_codes/explain")
  .send({error_codes})
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}


function getAllErrorCodes(callback, reject) {
  return SuperAgent
  .get(api_root + "/xenion/error_codes/all")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}


function updateErrorCode(error_code, description, callback, reject) {
  return SuperAgent
  .post(api_root + "/xenion/error_codes/update")
  .send({error_code})
  .send({description})
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function registerErrorCode(error_code, description, callback, reject) {
  return SuperAgent
  .post(api_root + "/xenion/error_codes/register")
  .send({error_code})
  .send({description})
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getMaintenanceSettings(device_serial, callback, reject) {
  return SuperAgent
  .get(api_root + "/xenion/" + device_serial + "/maintenance/settings")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getDeploymentInformation(device_serial, deployment_type, callback, reject) {
  return SuperAgent
  .get(api_root + "/xenion/" + device_serial + "/maintenance/deployment/" + deployment_type)
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function updateDeploymentInformation(device_serial, deployment, callback, reject) {
  return SuperAgent
  .post(api_root + "/xenion/" + device_serial + "/maintenance/deployment")
  .send({deployment})
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function setMaintenanceSetting(device_serial, settings, callback, reject) {
  return SuperAgent
  .post(api_root + "/xenion/" + device_serial + "/maintenance/settings")
  .send({settings})
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}