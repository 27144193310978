import React from "react"
import {connect} from 'react-redux'
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Card from "components/Card"
import CardBody from "components/CardBody"
import CardHeader from "components/CardHeader"

import extendedTablesStyle from "./extendedTablesStyle.jsx"

import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import {mapService} from "../../_services";

import ReactTable from "../../components/react-table";
import Button from "../../components/CustomButtons";
import {sweetAlertActions} from "../../_actions";
import CustomInput from "../../components/CustomInput";
import NavPills from "../../components/NavPills";
import {update_location_list} from "../helper_functions/locations";
import ClipboardButton from "../../components/ClipboardButoon";
import MyDropdown from "../../components/MyDropdown";

class Locations extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      totalResults: 0,
      totalPages: 0,
      pageSize: 10,
      currentPage: 1,
      loading: false,
      updated_properties: {},
      data: [],
      number_greenhouses: {},
      create_location: false
    }

    this.timer = null
    this._is_mounted = false
    this._refresh_rate = 3000
  }

  componentDidMount() {
    this._is_mounted = true
    this.refresh()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this._is_mounted = false
  }

  refresh() {
    const {currentPage, pageSize} = this.state
    this.setState({loading: true})

    mapService.getLocations(pageSize, currentPage - 1, result => {
      const total_pages = Math.ceil(result.total / pageSize)
      let newCurrentPage = currentPage
      if (currentPage > total_pages && result.total > 0) {
        newCurrentPage = total_pages
      }

      result.results.forEach((location) => {
        mapService.getGreenhousesForLocation(location["id"], res => {
          let {number_greenhouses} = this.state
          number_greenhouses[location["id"]] = res.greenhouses.length
          this.setState({number_greenhouses})
        })
      })

      this.setState({
        totalResults: result.total,
        currentPage: newCurrentPage,
        data: result.results,
        totalPages: total_pages,
        loading: false,
      })
    }, error => {
      this.setState({
        data: [],
        totalPages: 0,
        loading: false
      })
    });
  }

  change_value(value_name, value) {
    let {updated_properties} = this.state
    updated_properties[value_name] = value
    this.setState({updated_properties})
  }

  start_creating_location() {
    this.setState({create_location: true})
  }

  start_updating_location(location_id, location) {
    const {dispatch, organisations, nimbus_manager} = this.props
    const {updated_properties} = this.state

    dispatch(sweetAlertActions.clean({
      title: "Update Location",
      showCancel: true,
      onCancel: this.clear_popup.bind(this),
      cancelBtnText: "No",
      onConfirm: this.update_location.bind(this, location_id, location),
      confirmBtnText: "Yes",
      content: <div>

        <p><b>Leave empty if you do not want to update the property</b></p>
        <p><b>Name:</b> {location["name"]}</p>
        <CustomInput
          style={{
            fullWidth: true,
            width: "100%"
          }}
          id={"name"}
          labelText={"New name"}
          inputProps={{
            onChange: event => this.change_value("name", event.target.value),
            type: "text",
            value: updated_properties["name"]
          }}
        />
        <p><b>Client Name:</b> {location["client_name"]}</p>
        <CustomInput
          style={{
            fullWidth: true,
            width: "100%"
          }}
          id={"client_name"}
          labelText={"New client name"}
          inputProps={{
            onChange: event => this.change_value("client_name", event.target.value),
            type: "text",
            value: updated_properties["client_name"]
          }}
        />
        <p><b>Address:</b> {location["address"]}</p>
        <CustomInput
          style={{
            fullWidth: true,
            width: "100%"
          }}
          id={"address"}
          labelText={"New address"}
          inputProps={{
            onChange: event => this.change_value("address", event.target.value),
            type: "text",
            value: updated_properties["address"]
          }}
        />

        <p>
          <b>Owner:</b> {organisations[location["owned_by"]] ? organisations[location["owned_by"]]["name"] : location["owned_by"]}
        </p>
        {nimbus_manager ?
          <div>
            <p><b>Owner ID:</b> {location["owned_by"]}</p>
            <CustomInput
              style={{
                fullWidth: true,
                width: "100%"
              }}
              id={"owned_by"}
              labelText={"New Owner"}
              inputProps={{
                onChange: event => this.change_value("owned_by", event.target.value),
                type: "text",
                value: updated_properties["owned_by"]
              }}
            />
          </div>
          :
          "Can only be updated by your cloud manager"
        }
      </div>
    }))
  }


  get_network_information(location_id) {
    const {dispatch} = this.props

    mapService.getNetworkInformation(location_id, res => {
      if (res === null) {
        dispatch(sweetAlertActions.warning({
          title: "No network information found"
        }))
      } else {
        dispatch(sweetAlertActions.success({
          title: "Network Information - decrypted",
          content: <div>
            <p>This request was logged</p>
            <p><b>WiFi SSID: </b> {res.wifi_ssid}</p>
            <p><b>WiFi Password: </b> {res.wifi_password}</p>
          </div>
        }))
      }
    })
  }


  start_update_network_information(location_id) {
    const {dispatch} = this.props
    const {updated_properties} = this.state

    dispatch(sweetAlertActions.clean({
      title: "Update Network Information",
      showCancel: true,
      onCancel: this.clear_popup.bind(this),
      cancelBtnText: "No",
      onConfirm: this.update_network_information.bind(this, location_id),
      confirmBtnText: "Yes",
      content: <div>
        <CustomInput
          style={{
            fullWidth: true,
            width: "100%"
          }}
          id={"wifi_ssid"}
          labelText={"WiFi SSID"}
          inputProps={{
            onChange: event => this.change_value("wifi_ssid", event.target.value),
            type: "text",
            value: updated_properties["wifi_ssid"]
          }}
        />
        <CustomInput
          style={{
            fullWidth: true,
            width: "100%"
          }}
          id={"wifi_password"}
          labelText={"WiFi Password"}
          inputProps={{
            onChange: event => this.change_value("wifi_password", event.target.value),
            type: "text",
            value: updated_properties["wifi_password"]
          }}
        />
      </div>
    }))
  }

  update_network_information(location_id) {
    const {updated_properties} = this.state
    const {dispatch} = this.props;

    mapService.updateNetworkInformation(location_id, updated_properties["wifi_ssid"], updated_properties["wifi_password"], res => {
      dispatch(sweetAlertActions.clear())
      this.setState({
        updated_properties: {}
      }, () => this.refresh())
    })
  }


  update_location(location_id, location) {
    const {updated_properties} = this.state
    const {dispatch} = this.props;

    let new_name = undefined
    if (updated_properties["name"] !== undefined) {
      if (updated_properties["name"] !== location["name"]) {
        new_name = updated_properties["name"]
      }
    }

    let new_client_name = undefined
    if (updated_properties["client_name"] !== undefined) {
      if (updated_properties["client_name"] !== location["client_name"]) {
        new_client_name = updated_properties["client_name"]
      }
    }

    let new_owner = undefined
    if (updated_properties["owned_by"] !== undefined) {
      if (updated_properties["owned_by"] !== location["owned_by"]) {
        new_owner = updated_properties["owned_by"]
      }
    }

    let new_address = undefined
    if (updated_properties["address"] !== undefined) {
      if (updated_properties["address"] !== location["address"]) {
        new_address = updated_properties["address"]
      }
    }

    mapService.updateLocation(location_id, new_name, new_client_name, new_owner, new_address, res => {
      dispatch(sweetAlertActions.clear())
      update_location_list()
      this.setState({
        updated_properties: {}
      }, () => this.refresh())
    })
  }


  create_new_location() {
    const {updated_properties} = this.state
    const {dispatch} = this.props;
    mapService.createLocation(updated_properties["name"], updated_properties["address"], updated_properties["owned_by"], res => {
      dispatch(sweetAlertActions.success({title: "Location is created"}))
      update_location_list()
      this.setState({
        updated_properties: {},
        create_location: false
      }, () => this.refresh())
    })
  }

  clear_popup() {
    const {dispatch} = this.props;
    dispatch(sweetAlertActions.clear())
    this.setState({
      updated_properties: {}
    }, () => this.refresh())
  }

  render() {
    const {organisations, nimbus_manager, ...other} = this.props
    const {
      currentPage,
      data,
      pageSize,
      loading,
      totalResults,
      totalPages,
      number_greenhouses,
      updated_properties,
      create_location,
    } = this.state

    let filler_size = (currentPage - 1) * pageSize
    if (filler_size < 0) {
      filler_size = 0
    }
    let filler_array = Array(filler_size).keys()

    let columns = [
      {
        Header: "ID",
        accessor: "id",
        filterable: false,
        sortable: false,
        maxWidth: 50,
        Cell: row => {
          return <ClipboardButton text={row.value}/>
        }
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Client Name",
        accessor: "client_name",
      },
      {
        Header: "Owned By",
        accessor: "owned_by",
        Cell: row => {
          if (organisations[row.value]) {
            return organisations[row.value].name
          }
          return row.value
        }
      },
      // {
      //     Header: "Continental ID",
      //     accessor: "continental_id",
      // },
    ]

    columns.push(...[
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "# Greenhouses",
        accessor: "id",
        Cell: row => {
          return number_greenhouses[row.value]
        }
      },
      {
        Header: "Update Location",
        accessor: "id",
        Cell: row => {
          return <Button color={"primary"} simple
                         onClick={this.start_updating_location.bind(this, row.value, row.original)}><b>{"Update Location"}</b></Button>
        }
      },
      {
        Header: "Network Information",
        accessor: "id",
        Cell: row => {
          return <div>
            <Button color={"primary"} simple
                    onClick={this.get_network_information.bind(this, row.value)}><b>{"Get Info"}</b></Button>
            <Button color={"warning"} simple
                    onClick={this.start_update_network_information.bind(this, row.value)}><b>{"Update Info"}</b></Button>
          </div>
        }
      }
    ])

    let tabs = [
      {
        tabButton: "Octiva",
        tabContent: <GridContainer>
          <GridItem xs={10}>
            <h5># Results: {totalResults}</h5>
          </GridItem>
          <GridItem xs={2} style={{textAlign: "right"}}>
            <Button color={"primary"} simple
                    onClick={this.start_creating_location.bind(this)}><b>{"Create Location"}</b></Button>
          </GridItem>
          <GridItem xs={12}>
            <ReactTable
              columns={columns}
              data={[
                ...filler_array,
                ...data
              ]}
              sortable={false}
              filterable={false}
              loading={loading}
              pages={totalPages}
              page={currentPage - 1}
              pageSize={pageSize}
              onPageChange={(pageIndex) => this.setState({currentPage: pageIndex + 1}, () => this.refresh())}
              onPageSizeChange={(newPageSize) => {
                this.setState({
                  currentPage: 1,
                  pageSize: newPageSize
                }, () => this.refresh())
              }}
            />
          </GridItem>
        </GridContainer>,
      }
    ]

    return (
      <GridContainer>
        {create_location &&
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>
                <GridContainer>
                  <GridItem xs={10}>
                    <h4 style={{color: primaryColor}}><b>{"Create Location"}</b></h4>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <div>
                  <CustomInput
                    style={{
                      fullWidth: true,
                      width: "100%"
                    }}
                    id={"name"}
                    labelText={"Name"}
                    inputProps={{
                      onChange: event => this.change_value("name", event.target.value),
                      type: "text",
                      value: updated_properties["name"]
                    }}
                  />
                  <CustomInput
                    style={{
                      fullWidth: true,
                      width: "100%"
                    }}
                    id={"client_name"}
                    labelText={"Client Name"}
                    inputProps={{
                      onChange: event => this.change_value("client_name", event.target.value),
                      type: "text",
                      value: updated_properties["client_name"]
                    }}
                  />

                  <MyDropdown
                    input_label={"Owned By"}
                    name={"owned_by"}
                    value={updated_properties["owned_by"]}
                    handle_selection={
                      this.change_value.bind(this)
                    }
                    options={[
                      ...Object.keys(organisations).sort((a, b) => {
                        return organisations[a]["name"] < organisations[b]["name"] ? -1 : 1
                      }).map((organisation_id) => {
                        return {
                          id: organisation_id,
                          value: organisations[organisation_id]["name"]
                        }
                      })
                    ]}
                  />
                  <CustomInput
                    style={{
                      fullWidth: true,
                      width: "100%"
                    }}
                    id={"address"}
                    labelText={"Address"}
                    inputProps={{
                      onChange: event => this.change_value("address", event.target.value),
                      type: "text",
                      value: updated_properties["address"]
                    }}
                  />
                </div>
                <Button simple color={"primary"} onClick={this.create_new_location.bind(this)}> Create </Button>
                <Button simple color={"danger"} onClick={() => {
                  this.setState({create_location: false, updated_properties: {}})
                }}> Cancel </Button>
              </CardBody>
            </Card>
          </GridItem>
        }
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              <GridContainer>
                <GridItem xs={10}>
                  <h4 style={{color: primaryColor}}><b>{"Overview Locations"}</b></h4>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <NavPills
                {...other}
                tabs={tabs}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}


function mapStateToProps(state) {
  const {nimbus_manager} = state.authentication.user
  const {total_nr_organisations, organisations} = state.location
  return {
    total_nr_organisations, organisations, nimbus_manager
  }
}


export default withStyles(extendedTablesStyle)(connect(mapStateToProps)(Locations))
