import React from "react"
import {connect} from 'react-redux'
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Card from "components/Card"
import CardBody from "components/CardBody"
import CardHeader from "components/CardHeader"

import extendedTablesStyle from "./extendedTablesStyle.jsx"

import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import {deviceService} from "../../_services";
import Table from "../../components/Table";
import Button from "../../components/CustomButtons";
import {alertActions, sweetAlertActions} from "../../_actions";
import Check from "@material-ui/icons/CheckTwoTone";
import Close from "@material-ui/icons/CloseTwoTone";
import {Alert} from "@mui/material";
import {get_connection_icon} from "../helper_functions/device_icons";


class Actions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      upload_all_command_success: [],
      upload_all_command_error: []
    }

    this.timer = null
    this._is_mounted = false
    this._refresh_rate = 3000
  }

  componentDidMount() {
    this._is_mounted = true
    this.refresh()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this._is_mounted = false
  }

  refresh() {
  }

  clear_popup() {
    const {dispatch} = this.props;
    dispatch(sweetAlertActions.clear())
  }

  confirm_upload_all_incident_data() {
    const {dispatch} = this.props
    dispatch(sweetAlertActions.clean({
      title: "Are you sure?",
      showCancel: true,
      onCancel: this.clear_popup.bind(this),
      cancelBtnText: "No",
      onConfirm: this.upload_all_incident_data.bind(this),
      confirmBtnText: "Yes",
      content: ''
    }))
  }

  upload_all_incident_data() {
    const {dispatch, state_received_devices, all_devices} = this.props

    this.setState({upload_all_command_success: [], upload_all_command_error: []})

    let upload_all_command_success = []
    let upload_all_command_error = []
    all_devices.forEach((device) => {
      if (state_received_devices.includes(device["serial"])) {
        deviceService.uploadAllIncidentData(device["serial"], res => {
          dispatch(sweetAlertActions.clear())
          if (res.result) {
            upload_all_command_success.push(device["serial"])
            this.setState({upload_all_command_success})
          } else {
            upload_all_command_error.push(device["serial"])
            this.setState({upload_all_command_error})
            dispatch(alertActions.error("The command is not sent to " + device["name"]))
          }
        })
      }
    })
  }

  render() {
    const {state_received_devices, all_devices, classes, device_state, device_data} = this.props
    const {upload_all_command_success, upload_all_command_error} = this.state


    let device_list = []
    all_devices.map((device) => {
      if (state_received_devices.includes(device["serial"])) {
        let has_wifi = false
        if (device_data && device_data[device["serial"]] && device_data[device["serial"]]["has_wifi"]){
          has_wifi = device_data[device["serial"]]["has_wifi"]["has_wifi"]
        }

        device_list.push([
          get_connection_icon(classes, true, device_state[device["serial"]]["timestamp"], has_wifi),
          device["name"],
          new Date( device_state[device["serial"]]["timestamp"]).toLocaleString("nl-BE"),
          upload_all_command_success.includes(device["serial"]) ? (
            <Check/>
          ) : (
            upload_all_command_error.includes(device["serial"]) ? (
              <Close/>
            ) : (
              ""
            )
          )
        ])
      }
    })


    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader><h4 style={{color: primaryColor}}><b>{"Global Actions"}</b></h4></CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <Alert color={"warning"}> This may cause extra costs when devices are connected
                    through 4G, verify this! </Alert>
                  <br/>
                  <br/>
                </GridItem>
                <br/>
                <br/>
                <GridItem md={6}>
                  <Table
                    color={"primary"}
                    tableData={[
                      ["", <b>Device</b>, <b>Last Online</b>, ""],
                      ...device_list,
                      ["", "", "", ""]
                    ]}/>
                </GridItem>
                <GridItem md={6}>
                  <Button
                    color={"primary"}
                    disabled={state_received_devices.length === 0}
                    onClick={this.confirm_upload_all_incident_data.bind(this)}
                  >
                    Upload All
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

    )
  }
}


function mapStateToProps(state) {
  const {device_state, all_devices} = state.device_fleet

  let state_received_devices = Object.keys(device_state)
  return {
    state_received_devices, all_devices, device_state
  }
}


export default withStyles(extendedTablesStyle)(connect(mapStateToProps)(Actions))
