import {store} from "_helpers/store"

export const getBearerAccessToken = () =>{
  const state = store.getState()
  if (state && state.authentication.user) {
    if (state.authentication.user.access_token) {
      return 'Bearer ' + state.authentication.user.access_token
    }
  }
}

export const getAccessToken = () =>{
  const state = store.getState()
  if (state && state.authentication.user) {
    if (state.authentication.user.access_token) {
      return state.authentication.user.access_token
    }
  }
}

export const getCognitoGroups = () => {
  const state = store.getState()
  if (state && state.authentication.user && state.authentication.user.profile) {
    return state.authentication.user.profile["cognito:groups"]
  }
}

export const getUserType = () => {
  const state = store.getState()
  if (state && state.authentication.user && state.authentication.user.profile) {
    let group_str = state.authentication.user.profile["cognito:groups"].filter((group) => group.indexOf("user_type") >= 0).toString()
    return group_str.split(":")[1]
  }
}

export const getUserId = () => {
  const state = store.getState()
  if (state && state.authentication.user && state.authentication.user.profile) {
    return state.authentication.user.profile["cognito:username"]
  }
}