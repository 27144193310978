import React, {useEffect} from "react";
import {useAuth} from "react-oidc-context";
import {Route, Router, Switch} from 'react-router-dom'
import {createBrowserHistory} from 'history'
import WelcomePage from 'routes/WelcomePage'
import Dashboard from "routes/Dashboard.jsx";

import 'assets/scss/material-dashboard-pro-react.css?v=1.4.0'
import "react-vis/dist/style.css"
import PrivateRoute from "./components/PrivateRoute";
import {store} from "_helpers/store"
import {useDispatch} from "react-redux";
import {userActions} from "./_actions";

export const history = createBrowserHistory();

function App() {
  const auth = useAuth()
  const state = store.getState()
  const dispatch = useDispatch()
  const tokenRenewalInterval = 5 * 60 * 1000 // 5 minutes

  useEffect(() => {
    if (auth.isAuthenticated) {
      const handle = setInterval(() => {
        auth.signinSilent();
        const newUser = auth.user
        dispatch(userActions.updateUser(newUser))
      }, tokenRenewalInterval);
      return () => clearInterval(handle);
    }
  }, [auth, tokenRenewalInterval]);

  function WelcomePageWithState() {
    let loadingText = undefined;
    switch (auth.activeNavigator) {
      case "signinPopup":
        loadingText = "Signing you in in popup window..."
        break;
      default:
        break;
    }
    return (
      <WelcomePage loadingText={loadingText}/>
    )
  }

  if (state.authentication.user && auth.user) {
    return (<Router history={history}>
      <Switch>
        <Route exact path="/" component={WelcomePageWithState}/>
        <PrivateRoute exact component={Dashboard}/>
      </Switch>
    </Router>);
  }

  if (auth.isAuthenticated) {
    if (!state.authentication.user) {
      const newUser = auth.user
      dispatch(userActions.updateUser(newUser))
      history.push('/dashboard')
    }
  }

  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={WelcomePageWithState}/>
        <PrivateRoute exact component={Dashboard}/>
      </Switch>
    </Router>);
}

export default App;