import React from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Card from "components/Card"
import CardBody from "components/CardBody"
import CardHeader from "components/CardHeader"

// Assets
import userProfileStyles from "./userProfileStyles.jsx"

// Services
import {managementService, xenionService} from '_services'
import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import Button from "../../components/CustomButtons";
import {Cancel} from "@material-ui/icons";
import Check from "@material-ui/icons/CheckCircleTwoTone";
import Table from "../../components/Table";
import Edit from "@material-ui/icons/Edit";
import CustomInput from "../../components/CustomInput";
import ClipboardButton from "../../components/ClipboardButoon";
import {connect} from "react-redux";
import DeviceSelector from "./DeviceSelector";
import MyDropdown from "../../components/MyDropdown";
import {getUserId} from "../../_services/authenticate";


class DeviceMaintenance extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      settings: {},
      deployment_info: {},
      edit_setting: "",
      is_setting: false,
      all_life_cycle_stages: [],
      all_life_cycle_stages_names: {}
    }
    this._is_mounted = false
  }

  componentDidMount() {
    this._is_mounted = true
    const {device_serial} = this.props
    this.refresh(device_serial)
  }

  componentWillUnmount() {
    this._is_mounted = false
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.device_serial !== this.props.device_serial) {
      this.refresh(this.props.device_serial)
    }
  }

  refresh(device_serial) {
    xenionService.getMaintenanceSettings(device_serial, res => {
      this.setState({settings: res.settings, deployment_info: {}})
    })

    managementService.getAllLifeCycleStages(res => {
      let all_life_cycle_stages_names = {}

      res.result.forEach((life_cycle_stage) => {
        all_life_cycle_stages_names[life_cycle_stage.id] = life_cycle_stage.name
      })

      this.setState({all_life_cycle_stages: res.result, all_life_cycle_stages_names})
    })
  }

  get_deployment_info(deployment_type) {
    const {device_serial} = this.props
    const {deployment_info} = this.state
    xenionService.getDeploymentInformation(device_serial, deployment_type, res => {
      this.setState({deployment_info: {...deployment_info, [deployment_type]: res}})
    })
  }

  save_settings() {
    const {device_serial} = this.props
    const {new_settings} = this.state
    this.setState({is_setting: true})
    xenionService.setMaintenanceSetting(device_serial, new_settings, res => {
      this.cancel_settings()
    })
  }

  cancel_settings() {
    const {device_serial} = this.props
    this.setState({
      edit_setting: "",
      new_settings: {},
      is_setting: false,
    }, () => this.refresh(device_serial))
  }

  edit_setting(setting) {
    const {new_settings, settings} = this.state
    this.setState({edit_setting: setting, new_settings: {...new_settings, [setting]: settings[setting]}})
  }

  change_value(config_name, value) {
    const {new_settings} = this.state
    this.setState({new_settings: {...new_settings, [config_name]: value}})
  }

  render() {
    const {device_name} = this.props
    const {
      settings,
      edit_setting,
      new_settings,
      deployment_info,
      all_life_cycle_stages,
      all_life_cycle_stages_names,
      is_setting
    } = this.state;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <DeviceSelector disable_key_bindings={true}/>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader><h4 style={{color: primaryColor}}><b>Maintenance - {device_name}</b></h4>
            </CardHeader>
            <CardBody>
              <GridItem xs={12}>
                <Table
                  tableHeaderColor="primary"
                  tableData={[
                    [
                      <b>Device Name</b>,
                      edit_setting === "name" ?
                        <CustomInput
                          labelText={"New Name"}
                          inputProps={{
                            onChange: event => this.change_value("name", event.target.value),
                            type: "text",
                            value: new_settings['name']
                          }}
                        />
                        :
                        <span>{settings["name"]}</span>,
                      edit_setting === "name" ?
                        <div>
                          <Button justIcon round simple color={"primary"}
                                  onClick={this.save_settings.bind(this)}>
                            <Check/>
                          </Button>
                          <Button justIcon round simple color={"warning"}
                                  onClick={this.cancel_settings.bind(this)}>
                            <Cancel/>
                          </Button>
                        </div>
                        :
                        <Button justIcon round simple color={"primary"}
                                onClick={this.edit_setting.bind(this, "name")}>
                          <Edit/>
                        </Button>,
                      "It can take up to 48h to change the name in all dashboards and updates"
                    ],
                    [
                      <b>Life Cycle Stage</b>,
                      edit_setting === "life_cycle_stage" ?
                        <div>
                          <MyDropdown
                            input_label={"Life Cycle Stage"}
                            name={"life_cycle_stage"}
                            value={new_settings['life_cycle_stage']}
                            default_value={new_settings['life_cycle_stage']}
                            options={[...all_life_cycle_stages.map((item) => {
                              return {id: item.id, value: item.name}
                            })]}
                            handle_selection={this.change_value.bind(this)}
                          />
                        </div>
                        :
                        <span>{all_life_cycle_stages_names[settings["life_cycle_stage"]]}</span>,
                      edit_setting === "life_cycle_stage" ?
                        <div>
                          <Button justIcon round simple color={"primary"}
                                  disabled={is_setting}
                                  onClick={this.save_settings.bind(this)}>
                            <Check/>
                          </Button>
                          <Button justIcon round simple color={"warning"}
                                  disabled={is_setting}
                                  onClick={this.cancel_settings.bind(this)}>
                            <Cancel/>
                          </Button>
                        </div>
                        :
                        <Button justIcon round simple color={"primary"}
                                onClick={this.edit_setting.bind(this, "life_cycle_stage")}>
                          <Edit/>
                        </Button>,
                      <span>
                                                It can take up to 48h to change the life cycle stage in all dashboards and updates
                                                <br/>
                        {
                          settings["life_cycle_stage"] === "TESTING" &&
                          <span>This device will be ignored in the slack treatment updates</span>
                        }
                                            </span>
                    ],
                    [
                      <b>SSH Credentials RPI</b>,
                      deployment_info["pi"] !== undefined ?
                        <div>
                          <b>Username: </b>
                          {
                            deployment_info["pi"] &&
                            <span>
                                                            {deployment_info["pi"]["username"]}
                              <ClipboardButton text={deployment_info["pi"]["username"]}/>
                                                        </span>
                          }
                          <br/>
                          <b>Password: </b>
                          {
                            deployment_info["pi"] &&
                            <span>
                                                            {deployment_info["pi"]["password"]}
                              <ClipboardButton
                                text={deployment_info["pi"]["password"]}/>
                                                        </span>
                          }
                        </div> :
                        <div>
                          <b>Username: </b> <span>******</span><br/>
                          <b>Password: </b> <span>******</span>
                        </div>,
                      <Button round simple color={"primary"}
                              onClick={this.get_deployment_info.bind(this, "pi")}>
                        Request Information
                      </Button>,
                      "Every request is logged"
                    ],
                    [
                      <b>SSH Credentials NUC</b>,
                      deployment_info["nuc"] !== undefined ?
                        <div>
                          <b>Username: </b>
                          {
                            deployment_info["nuc"] &&
                            <span>
                                                            {deployment_info["nuc"]["username"]}
                              <ClipboardButton text={deployment_info["nuc"]["username"]}/>
                                                        </span>
                          }
                          <br/>
                          <b>Password: </b>
                          {
                            deployment_info["nuc"] &&
                            <span>
                                                            {deployment_info["nuc"]["password"]}
                              <ClipboardButton text={deployment_info["nuc"]["password"]}/>
                                                        </span>
                          }
                        </div> :
                        <div>
                          <b>Username: </b> <span>******</span><br/>
                          <b>Password: </b> <span>******</span>
                        </div>,
                      <Button round simple color={"primary"}
                              onClick={this.get_deployment_info.bind(this, "nuc")}>
                        Request Information
                      </Button>,
                      "Every request is logged"
                    ],
                    [
                      <b>Login Information Router</b>,
                      deployment_info["router"] !== undefined ?
                        <div>
                          <b>Username: </b>
                          {
                            deployment_info["router"] &&
                            <span>
                                                            {deployment_info["router"]["username"]}
                              <ClipboardButton
                                text={deployment_info["router"]["username"]}/>
                                                        </span>
                          }
                          <br/>
                          <b>Password: </b>
                          {
                            deployment_info["router"] &&
                            <span>
                                                            {deployment_info["router"]["password"]}
                              <ClipboardButton
                                text={deployment_info["router"]["password"]}/>
                                                        </span>
                          }
                        </div> :
                        <div>
                          <b>Username: </b> <span>******</span><br/>
                          <b>Password: </b> <span>******</span>
                        </div>,
                      <Button round simple color={"primary"}
                              onClick={this.get_deployment_info.bind(this, "router")}>
                        Request Information
                      </Button>,
                      "Every request is logged"
                    ],
                    [
                      "", "", "", ""
                    ]
                  ]}
                />
              </GridItem>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

function mapStateToProps(state) {
  const {device_serial, all_devices, device_state} = state.device_fleet
  let in_control = false

  if (device_state && device_state[device_serial] && device_state[device_serial].state) {
    in_control = device_state[device_serial].state.controller === getUserId()
  }

  let device_name = ""
  all_devices.forEach((device)=>{
    if (device["serial"] === device_serial){
      device_name = device["name"]
    }
  })

  return {
    device_serial, in_control, device_name
  }
}


export default withStyles(userProfileStyles)(connect(mapStateToProps)(DeviceMaintenance))
