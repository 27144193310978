import {userConstants} from '_constants'

export const userActions = {
  updateName,
  updateUser
};

function updateName(first_name, lastname) {
  return {type: userConstants.UPDATE_NAME, first_name, lastname}
}

function updateUser(user) {
  return {type: userConstants.UPDATE_USER, user}
}