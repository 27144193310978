export function beautify_name(name) {
  if (name === undefined)
    return ''

  if (typeof name !== 'string') {
    if (isNaN(name))
      return ''
    return name
  }
  name = name.toLowerCase()
  const pieces = []
  name.split('_').forEach((piece) => {
    pieces.push(piece.charAt(0).toUpperCase() + piece.slice(1))
  })
  return pieces.join(' ')
}


export function numberWithSpaces(x) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
}

export function lowercaseWithSpaces(x) {
  if (x === undefined || x === null) {
    return ""
  }
  let words = []
  x.split("_").forEach((word) => {
    words.push(word.charAt(0) + word.substring(1).toLowerCase())
  })
  return words.join(" ")
}

export function lumion_fix_translate_state(current_state, current_task){
    if (current_state === undefined || current_state === null) {
      return "Unknown"
    }

    if (current_task === undefined || current_task === null) {
      current_task = ""
    }

    try {
        current_state = current_state.toLowerCase()
    } catch (e) {
      return "Unknown"
    }
    try {
        current_task = current_task.toLowerCase()
    } catch (e) {

    }

    if (current_state === "error") {
      return "Error"
    }

    if (current_state === "waiting_for_task") {
      return "Idle"
    }

    if (current_state === "executing_task") {
      if (current_task === "restarting") {
        return "Reconfiguring Software"
      } else if (current_task === "resetting") {
        return "Reconfiguring Software"
      } else if (current_task === "drive_manually") {
        return "Manual Driving"
      } else if (current_task === "waiting") {
        return "Waiting to start treatment"
      } else if (current_task === "recovering") {
        return "In Recovery"
      } else if (current_task === "finishing_current") {
        return "Finishing Current Lane"
      } else if (current_task === "starting") {
        return "Starting the treatment"
      } else if (current_task === "calibration") {
        return "In Calibration"
      } else if (current_task === "initializing") {
        return "Initializing"
      } else if (current_task === "drive_task") {
        return "Autonomously Driving"
      } else if (current_task === "uv_treatment") {
        return "Executing UV Treatment"
      } else if (current_task === "init_sideshift") {
        return "Moving Sideshift"
      } else {
        return `Executing Unknown Action (${current_task})`
      }
    }
    return "Unknown"

}

export function lumion_fix_in_treatment(current_state, current_task){
    if (current_state === undefined || current_state === null) {
      return false
    }

    if (current_task === undefined || current_task === null) {
      current_task = ""
    }

    try {
        current_state = current_state.toLowerCase()
    } catch (e) {
      return false
    }
    try {
        current_task = current_task.toLowerCase()
    } catch (e) {

    }

    if (current_state === "error" || current_state === "waiting_for_task") {
      return false
    }

    if (current_state === "executing_task") {
      return ["waiting", "recovering", "finishing_current", "starting", "uv_treatment"].includes(current_task);
    }
    return false

}