import React from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"

// Assets
import userProfileStyles from "./userProfileStyles.jsx"

// Services
import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import Table from "../../components/Table";
import {get_battery_icon, get_connection_icon, time_to_h_and_min} from "../helper_functions/device_icons";
import ReactTimeAgo from "react-time-ago";
import {beautify_name, lumion_fix_in_treatment, lumion_fix_translate_state} from "../helpers";
import {connect} from "react-redux";
import {deviceService} from "../../_services";
import GreenhouseMap from "../Maps/GreenhouseMap";
import {Icon} from "leaflet";
import {Alert} from "@mui/material";
import {CloseTwoTone, DoneTwoTone} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";


class LumionFixDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error_overview: [],
      last_websocket_connection: null,
      last_refresh: null,
      last_error_refresh: null,
      last_sw_refresh: null,
      last_map_refresh: null,
      state_name: "My State Name",
      battery_level: -1,
      sw_info: {
        software_hash: "",
        software_manually_started: "",
        software_state: "",
        software_version: ""
      },
      is_live: false,
      has_wifi: false,
      current_site_id: null,
      current_site_version: null,
      currently_in_treatment: false,
      error_code: 0,
      sim_info: {},
      current_treatment: {},
      current_location: {
        node_id: "",
        x: -1,
        y: -1,
        theta: -1,
        velocity: -1
      },
      available_maps: {}
    }

    this.timer = null
    this._is_mounted = false
    this._refresh_rate = 3000
  }

  componentDidMount() {
    this._is_mounted = true
    const {device_serial} = this.props
    this.refresh(device_serial)
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this._is_mounted = false
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.device_serial !== this.props.device_serial) {
      this.refresh(this.props.device_serial)
    }
    if (prevProps && prevProps.device_state && (prevProps.device_state[prevProps.device_serial] !== this.props.device_state[this.props.device_serial])) {
      this.refresh_websocket_state(this.props.device_state[this.props.device_serial])
    }
    if (prevProps && prevProps.device_data && (prevProps.device_data[prevProps.device_serial] !== this.props.device_data[this.props.device_serial])) {
      this.refresh_websocket_data(this.props.device_data[this.props.device_serial])
    }
  }

  refresh_websocket_data(websocket_data) {
    let state_to_update = {}

    if ("has_wifi" in websocket_data) {
      state_to_update["has_wifi"] = websocket_data["has_wifi"]["has_wifi"]
    }

    this.setState(state_to_update)
  }


  refresh_websocket_state(websocket_state) {
    this.setState({
      current_location: {
        node_id: websocket_state["state"]["current_node"],
        x: websocket_state["state"]["current_pose_x"],
        y: websocket_state["state"]["current_pose_y"],
        theta: websocket_state["state"]["current_pose_theta"],
        velocity: websocket_state["state"]["current_velocity"]
      },
      error_code: websocket_state["state"]["error_code"],
      battery_level: websocket_state["state"]["battery_level"],
      last_websocket_connection: websocket_state["timestamp"],
      state_name: lumion_fix_translate_state(websocket_state["state"]["current_state"], websocket_state["state"]["current_task"]),
      currently_in_treatment: lumion_fix_in_treatment(websocket_state["state"]["current_state"], websocket_state["state"]["current_task"])
    })
  }

  refresh(device_serial) {
    deviceService.getDeviceStateData(device_serial, res => {
      this.setState({
        has_wifi: res["has_wifi/has_wifi"],
        current_site_id: res["site/site_id"],
        current_site_version: res["site/site_version"],
      })
    })
    deviceService.getErrorOverview(device_serial, 48, res => {
      this.setState({error_overview: res.result, last_error_refresh: new Date()})
    })
    deviceService.getDeviceSwVersion(device_serial, res => {
      this.setState({sw_info: res.sw_version, last_sw_refresh: new Date()})
    })
    deviceService.getDeviceSimInfo(device_serial, res => {
      this.setState({sim_info: res.sim_info.sim_info, last_sim_refresh: new Date()})
    })
    deviceService.getDeviceActiveTreatment(device_serial, res => {
      this.setState({current_treatment: res, last_treatment_refresh: new Date()})
    })
    deviceService.getDeviceMapData(device_serial, res => {
      this.setState({
        last_map_refresh: res.time,
        available_maps: res.available_maps,
      })
    })
    deviceService.getDeviceRawState(device_serial, res => {
      let state = res.state
      this.setState({
        is_live: state.is_live,
        current_location: {
          node_id: state["state"]["current_node"],
          x: state["state"]["current_pose_x"],
          y: state["state"]["current_pose_y"],
          theta: state["state"]["current_pose_theta"],
          velocity: state["state"]["current_velocity"]
        },
        error_code: state["state"]["error_code"],
        battery_level: state["state"]["battery_level"],
        last_refresh: state["timestamp"],
        state_name: lumion_fix_translate_state(state["state"]["current_state"], state["state"]["current_task"]),
        currently_in_treatment: lumion_fix_in_treatment(state["state"]["current_state"], state["state"]["current_task"])
      })
    })

    if (this._is_mounted){
      setTimeout(this.refresh.bind(this, device_serial), 5000)
    }
  }

  render() {
    const {device_serial, classes, device_name, maps} = this.props
    const {
      error_overview, state_name, available_maps, last_websocket_connection, has_wifi, current_site_id,
      current_site_version, last_error_refresh, is_live, last_sw_refresh, last_sim_refresh, last_map_refresh, sw_info,
      sim_info, error_code, battery_level, current_treatment, current_location, last_treatment_refresh
    } = this.state

    let current_location_data = [["", ""]]

    if (current_location) {
      current_location_data = []
      current_location_data.push([<b>Current node</b>, current_location["node_id"]])
      current_location_data.push([<b>Current X</b>, current_location["x"]])
      current_location_data.push([<b>Current Y</b>, current_location["y"]])
      current_location_data.push([<b>Current theta</b>, current_location["theta"]])
      current_location_data.push([<b>Current velocity</b>, current_location["velocity"]])
      current_location_data.push(["", ""])
    }

    let treatment_data = []
    if (current_treatment){
      if (Object.keys(current_treatment).length !== 0) {
        treatment_data.push([<b>Treatment ID</b>, current_treatment["treatment_id"]])
        treatment_data.push([<b>Mission
          Name</b>, current_treatment["settings"] ? current_treatment["settings"]["mission_id"] : ""])

        let start_time = current_treatment["start_time"]
        let creation_time = current_treatment["creation_time"]


        let wait_time = 0
        Object.keys(current_treatment["jobs"]).forEach((job_id) => {
          let job = current_treatment["jobs"][job_id]
          if (job["job_type"] === "waiting") {
            wait_time = parseFloat(job["object_id"].split(" - ")[1])
          }
        })

        let table_value = ""
        if (start_time === null || start_time === "" || start_time === undefined) {
          let date = new Date(creation_time)

          let new_time = date.getTime() + (wait_time * 1000)
          table_value = <i>{new Date(new_time).toLocaleString("nl-BE")} (estimated)</i>
        } else {
          let date = new Date(start_time)
          table_value = date.toLocaleString("nl-BE")
        }
        treatment_data.push([<b>Start Time</b>, table_value])
        treatment_data.push(["", ""])
      } else {
        treatment_data.push(["There is no active treatment"])
        treatment_data.push([""])
      }
    }

    let sw_version = []

    if (sw_info) {
      if ("software_version" in sw_info) {
        sw_version.push([<b>Software Version</b>, sw_info["software_version"]])
      }
      if ("software_hash" in sw_info) {
        sw_version.push([<b>Software Hash</b>, sw_info["software_hash"]])
      }
      if ("software_status" in sw_info) {
        let status = sw_info["software_status"]
        let manually_started = sw_info["software_manually_started"]
        if (status === "True") {
          status = "Software Version is valid"
        }
        if (status === "False") {
          status = "Software on NUC and RPI is different"
        }
        if (status === "MissingExpected") {
          status = "The expected software version is missing on RPI or on the NUC"
        }
        if (status === "PI_error") {
          status = "There is an error with the software version on RPI"
        }
        if (status === "NUC_error") {
          status = "There is an error with the software version on RPI"
        }
        if (manually_started === true) {
          status = "Nuc and/or RPi have been started manually"
        }

        sw_version.push([<b>Software Status</b>, status])
      }
      if ("software_manually_started" in sw_info) {
        let manually_started = sw_info["software_manually_started"]

        if (manually_started === true) {
          sw_version.push([<b>Warning:</b>, "Nuc and/or RPi have been started manually"])
        }
      }
      sw_version.push(["", ""])
    }

    let sim_info_table = []

    if (sim_info) {
      if (Object.keys(sim_info).length !== 0) {
        sim_info_table.push([<b>Customer</b>, sim_info["customer"]])
        sim_info_table.push([<b>Rate
          Plan</b>, showWarning(sim_info["rate_plan"], sim_info["rate_plan"].indexOf("Keep Alive") > -1)])
        sim_info_table.push([<b>Status</b>, sim_info["status"]])
        sim_info_table.push([<b>CTD Usage</b>, getCtdUsageString(sim_info["ctd_data_usage"])])
        sim_info_table.push([<b>Limit Reached</b>, sim_info["overage_limit_reached"] ? "Yes" : "No"])
        sim_info_table.push([<b>Limit Override</b>, sim_info["overage_limit_override"]])
        sim_info_table.push(["", ""])
      }
    }

    let maps_table = [["", "", <b>S</b>, <b>M</b>, <b>CO</b>, <b>G</b>, <b>O</b>, <b>SE</b>, <b>TO</b>, <b>UV</b>,
      <b>VE</b>, "Valid?"]]

    if (Object.keys(available_maps).length !== 0) {
      if (current_site_id && current_site_version) {
        let [base, obs, miss, gutt, uv, sen, to, con, ve] = current_site_version.split("#")
        maps_table.push([<b>Current
          Site:</b>, current_site_id, base, miss, con, gutt, obs, sen, to, uv, ve, ""])
      }

      if (available_maps) {
        Object.keys(available_maps).forEach((map_id, index) => {
          let available_map = available_maps[map_id]
          let title = <div
            key={index}
            style={{fontSize: "0.9rem"}}
          >
            <ul
              style={{fontSize: "0.9rem"}}
            >
              {Object.keys(available_map["errors"]).map((error_key, map_index) => {
                return <li key={map_index}>
                  <b>{error_key}</b>: {available_map["errors"][error_key]}
                </li>
              })
              }
            </ul>
          </div>

          let [base, obs, miss, gutt, uv, sen, to, con, ve] = available_map["version"].split("#")
          maps_table.push(
            [
              index === 0 ? <b>Other Sites:</b> : "",
              (maps && maps[map_id] ? maps[map_id]["name"] : "...") + " (" + map_id + ")",
              base, miss, con, gutt, obs, sen, to, uv, ve,
              <Tooltip
                id="tooltip2"
                title={available_map["valid"] ? "Correct Map" : title}
                placement="right"
                classes={{tooltip: classes.tooltip}}
                style={{fontSize: "0.9rem"}}
              >
                                <span
                                  style={{color: available_map["valid"] ? "rgb(100,169,68)" : "rgb(231,92,92)"}}>{available_map["valid"] ?
                                  <DoneTwoTone/> : <CloseTwoTone/>}</span>
              </Tooltip>


            ])
        })
      }
      maps_table.push(["", "", "", "", "", "", "", "", "", "", "", ""])
    }

    const red_icon = new Icon({
      iconUrl: require('assets/icons/Fix.svg'),
      iconSize: [50, 50],
      iconAnchor: [25, 25],
      popupAnchor: [0, -20]
    });

    return (
      <GridContainer>
        <GridItem xs={12} md={6}>
          <h5 style={{color: primaryColor}}>General</h5>
          <Table
            color={"primary"}
            tableData={[
              [
                <b>Serial Number</b>,
                device_serial
              ],
              [
                <b>Name</b>,
                <div>
                  {
                    is_live &&
                    has_wifi &&
                    get_connection_icon(classes, is_live, last_websocket_connection, has_wifi)
                  }
                  &nbsp; &nbsp;
                  {device_name}
                </div>
              ],
              [
                <b>Last Connection</b>,
                last_websocket_connection ? (
                  <span>
                                        {new Date(Date.parse(last_websocket_connection)).toLocaleString('nl-BE')}
                    &nbsp;&nbsp;(&nbsp;<ReactTimeAgo date={last_websocket_connection} locale="en-UK"/>&nbsp;)
                                        </span>
                ) : "Never connected"
              ],
              ["", ""]
            ]}/>
        </GridItem>
        <GridItem xs={12} md={6}>
          <h5 style={{color: primaryColor}}>Maps </h5>
          <p> Updated: {last_map_refresh ? <ReactTimeAgo date={last_map_refresh} locale="en-UK"/> : "?"}</p>
          <Table
            color={"primary"}
            tableData={maps_table}/>
        </GridItem>
        <GridItem xs={12} md={4}>
          <h5 style={{color: primaryColor}}>State </h5>
          <p> Updated: {last_websocket_connection ?
            <ReactTimeAgo date={last_websocket_connection} locale="en-UK"/> : "?"}</p>
          <Table
            color={"primary"}
            tableData={[
              [
                <b>State</b>,
                state_name.toLowerCase() === 'error' ?
                  <span
                    style={{color: 'rgb(255,0,0)'}}>{beautify_name(state_name)} - {error_code}</span>
                  :
                  <p>{beautify_name(state_name)}</p>
              ],
              [
                <b>Battery Level</b>,
                get_battery_icon(battery_level)
              ],
              ["", ""]
            ]}/>
        </GridItem>
        <GridItem xs={12} md={4}>
          <h5 style={{color: primaryColor}}>Software Version </h5>
          <p> Updated: {last_sw_refresh ? <ReactTimeAgo date={last_sw_refresh} locale="en-UK"/> : "?"}</p>
          <Table
            color={"primary"}
            tableData={sw_version}/>
        </GridItem>
        <GridItem xs={12} md={4}>
          <h5 style={{color: primaryColor}}>Sim Information </h5>
          <p> Updated: {last_sim_refresh ? <ReactTimeAgo date={last_sim_refresh} locale="en-UK"/> : "?"}</p>
          <Table
            color={"primary"}
            tableData={sim_info_table}/>
        </GridItem>
        <GridItem xs={12} md={6}>
          <h5 style={{color: primaryColor}}>Error States Last 48h </h5>
          <p> Updated: {last_error_refresh ? <ReactTimeAgo date={last_error_refresh} locale="en-UK"/> : "?"}</p>
          <Table
            color={"primary"}
            tableData={[
              [<b>Start Time</b>, <b>Stop Time</b>, <b>Duration</b>, <b>Next State</b>],
              ...error_overview.map((error_overview) => {
                return [
                  new Date(error_overview["start_time"]).toLocaleString('nl-BE'),
                  new Date(error_overview["stop_time"]).toLocaleString('nl-BE'),
                  time_to_h_and_min(error_overview["duration_seconds"]),
                  error_overview["next_state"]
                ]
              }),
              ["", "", "", ""],
            ]}/>
        </GridItem>
        <GridItem xs={12} md={6}>
          <h5 style={{color: primaryColor}}>Current Treatment </h5>
          <p> Updated: {last_treatment_refresh ? <ReactTimeAgo date={last_treatment_refresh} locale="en-UK"/> : "?"}</p>
          <Table
            color={"primary"}
            tableData={treatment_data}/>
        </GridItem>
        <GridItem xs={12} md={12}>
          <h5 style={{color: primaryColor}}>Current Location </h5>
          <p> Updated: {last_websocket_connection ?
            <ReactTimeAgo date={last_websocket_connection} locale="en-UK"/> : "?"}</p>
          {current_site_id && current_site_version && current_location &&
            <GreenhouseMap
              map_id={current_site_id}
              map_version={current_site_version}
              markers={[{
                "key": "current_position",
                "popup": device_name,
                "x": current_location["x"] ? current_location["x"] : 0,
                "y": current_location["y"] ? current_location["y"] : 0,
                "icon": red_icon,
              }]
              }
              alternate={
                <Table
                  color={"primary"}
                  tableData={current_location_data}
                />
              }
            />
          }
        </GridItem>
      </GridContainer>
    )
  }
}

function mapStateToProps(state) {
  const {device_serial, device_state, device_data, all_devices} = state.device_fleet
  const {maps} = state.location

  let device_name = ""
  all_devices.forEach((device) => {
    if (device["serial"] === device_serial) {
      device_name = device["name"]
    }
  })

  return {
    device_serial, device_name, device_state, device_data, maps
  }
}

function getCtdUsageString(bytes) {
  const units = ['Bytes', 'KB', 'MB', 'GB']
  let outputNumber = bytes
  let conversionCount = 0
  while (outputNumber >= 1000 && conversionCount < units.length) {
    outputNumber = Math.floor(outputNumber / 10.24) / 100
    conversionCount += 1
  }
  return `${outputNumber} ${units[conversionCount]}`
}

function showWarning(text, show) {
  return (show ? <Alert severity={"warning"}> {text} </Alert> : {text})
}


export default withStyles(userProfileStyles)(connect(mapStateToProps)(LumionFixDetail))
