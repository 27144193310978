import SuperAgent from 'superagent'
import {api_root} from 'index.js'
import {errorHandling, unauthorizedRedirect} from './error.handler.js'
import {getBearerAccessToken} from "./authenticate";

export const deviceService = {
  getDeviceConfig,
  deployDevice,
  getDeviceLogs,
  getDeviceEvents,
  getErrorOverview,
  syncTreatments,
  getDeviceRawState,
  getDeviceRpState,
  getDeviceSwVersion,
  getDeviceActiveTreatment,
  getDeviceStateData,
  getDeviceSimInfo,
  getDeviceMapData,
  waitOnEvent,
  releaseControl,
  takeControl,
  stopImmediately,
  uploadIncidentData,
  uploadAllIncidentData,
  validateMaps,
  reloadConfig,
  getDeviceActions,
  getDevice,
  getDeviceDecrypted,
  getCredentials,
  getAccessToken,
  getQRCode,
};


function getAccessToken(device_serial, callback, reject) {
  return SuperAgent
  .get(api_root + "/device/" + device_serial + "/access_token")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const FileSaver = require('file-saver');
    const text = response.text;
    const data = text && JSON.parse(text);
    let blob = new Blob([JSON.stringify(data, null, 4)], {type: "text/plain;charset=utf-8"});
    FileSaver.saveAs(blob, device_serial + ".json")
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}


function getQRCode(device_serial, callback, reject) {
  return SuperAgent
  .get(api_root + "/device/" + device_serial + "/qr-code")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const FileSaver = require('file-saver');
    const base64Data = response.text;
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/png' });
    FileSaver.saveAs(blob, device_serial + ".png")
    callback(response)
  })
  .catch(reject)
  .catch(errorHandling)
}


function getDeviceActions(device_serial, action_type, per_page, page, callback, reject) {
  return SuperAgent
  .get(api_root + "/device/actions")
  .query({device_serial})
  .query({action_type})
  .query({per_page})
  .query({page})
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getDevice(device_serial, callback, reject) {
  return SuperAgent
  .get(api_root + "/device/" + device_serial)
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getDeviceDecrypted(device_serial, callback, reject) {
  return SuperAgent
  .get(api_root + "/device/" + device_serial + "/decrypted")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function deployDevice(device_serial, location_id, callback, reject) {
  return SuperAgent
  .post(api_root + "/device/" + device_serial + "/deploy")
  .send({location_id})
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getCredentials(device_serial, callback, reject) {
  return SuperAgent
  .get(api_root + "/device/" + device_serial + "/credentials")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getDeviceConfig(device_serial, callback, reject) {
  return SuperAgent
  .get(api_root + "/machine/" + device_serial + "/config")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getDeviceLogs(device_serial, callback, reject) {
  return SuperAgent
  .get(api_root + "/machine/" + device_serial + "/logs")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getDeviceEvents(device_serial, callback, reject) {
  return SuperAgent
  .get(api_root + "/machine/" + device_serial + "/events")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getErrorOverview(device_serial, prev_hours, callback, reject) {
  return SuperAgent
  .get(api_root + "/machine/" + device_serial + "/error_overview/" + prev_hours)
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function syncTreatments(device_serial, callback, reject) {
  return SuperAgent
  .get(api_root + "/machine/" + device_serial + "/treatments/sync")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function stopImmediately(device_serial, callback, reject) {
  return SuperAgent
  .get(api_root + "/machine/" + device_serial + "/stop_immediately")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function reloadConfig(device_serial, callback, reject) {
  return SuperAgent
  .get(api_root + "/machine/" + device_serial + "/reload_config")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function uploadAllIncidentData(device_serial, callback, reject) {
  return SuperAgent
  .get(api_root + "/machine/" + device_serial + "/upload_data/all")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function validateMaps(device_serial, callback, reject) {
  return SuperAgent
  .get(api_root + "/machine/" + device_serial + "/validate_maps")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function uploadIncidentData(device_serial, database_id, callback, reject) {
  return SuperAgent
  .get(api_root + "/machine/" + device_serial + "/upload_data/" + database_id)
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getDeviceRawState(device_serial, callback, reject) {
  return SuperAgent
  .get(api_root + "/machine/" + device_serial + "/raw_state")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getDeviceRpState(device_serial, callback, reject) {
  return SuperAgent
  .get(api_root + "/machine/" + device_serial + "/rp_state")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}



function getDeviceStateData(device_serial, callback, reject) {
  return SuperAgent
  .get(api_root + "/machine/" + device_serial + "/state_data")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}


function getDeviceSwVersion(device_serial, callback, reject) {
  return SuperAgent
  .get(api_root + "/machine/" + device_serial + "/sw_version")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}


function getDeviceSimInfo(device_serial, callback, reject) {
  return SuperAgent
  .get(api_root + "/machine/" + device_serial + "/sim_info")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getDeviceMapData(device_serial, callback, reject) {
  return SuperAgent
  .get(api_root + "/machine/" + device_serial + "/map_data")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getDeviceActiveTreatment(device_serial, callback, reject) {
  return SuperAgent
  .get(api_root + "/machine/" + device_serial + "/active_treatment")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function takeControl(device_serial, callback, reject) {
  return SuperAgent
  .get(api_root + "/machine/" + device_serial + "/take_control")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function releaseControl(device_serial, callback, reject) {
  return SuperAgent
  .get(api_root + "/machine/" + device_serial + "/release_control")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function waitOnEvent(device_serial, event_name, callback, reject) {
  return SuperAgent
  .get(api_root + "/machine/" + device_serial + "/wait/" + event_name)
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}