import React from "react"
import {connect} from 'react-redux'
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Card from "components/Card"
import CardBody from "components/CardBody"
import CardHeader from "components/CardHeader"

import extendedTablesStyle from "./extendedTablesStyle.jsx"

import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import {xenionService} from "../../_services";
import MyReactTable from "../../components/MyReactTable/MyReactTable";
import {filterSubstring} from "../helper_functions/table_filtering";
import FormControl from "@material-ui/core/FormControl";
import Button from "../../components/CustomButtons";
import Edit from "@material-ui/icons/Edit";
import Table from "../../components/Table";
import CustomInput from "../../components/CustomInput";
import MyDropdown from "../../components/MyDropdown";


class ErrorCodes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error_codes: [],
      languages: [],
      chosen_language: "development",
      selected_error_code: null,
      add_error_code: false,
      changed_values: {},
      new_languages: [],
      new_lan: "",
      new_error_code: "",
      selected_language: ""
    }

    this.timer = null
    this._is_mounted = false
    this._refresh_rate = 3000
  }

  change_value(lan, value) {
    const {changed_values} = this.state
    this.setState({changed_values: {...changed_values, [lan]: value}})
  }

  change_new_lan(value) {
    this.setState({new_lan: value})
  }

  change_new_error_code(value) {
    this.setState({new_error_code: value})
  }

  componentDidMount() {
    this._is_mounted = true
    this.refresh()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this._is_mounted = false
  }

  handleLanguage(name, value) {
    this.setState({
      "chosen_language": value
    })
  }

  handleAddLanguage(name, value) {
    this.setState({
      "selected_language": value,
    })
  }

  refresh() {
    xenionService.getAllErrorCodes(res => {
      this.setState({error_codes: res.error_codes, languages: res.languages})
      if (this._is_mounted) {
        // this.timer = setTimeout(() => this.refresh(), this._refresh_rate)
      }
    })
  }

  select_error_code(error_code) {
    this.setState({selected_error_code: error_code})
  }

  cancel() {
    this.setState({selected_error_code: null, add_error_code: false})
  }

  new_error_code() {
    const {new_languages} = this.state
    this.setState({new_languages: [...new_languages, "en"], selected_error_code: null, add_error_code: true})
  }

  download_error_codes() {
    const {chosen_language} = this.state
    xenionService.exportErrorCodes(chosen_language, "error_codes.pdf", res => {
    })
  }

  edit() {
    const {changed_values, selected_error_code, add_error_code, new_error_code} = this.state

    if (add_error_code) {
      xenionService.registerErrorCode(new_error_code, changed_values, res => {
        if (res.result) {
          this.refresh()
          this.setState({
            changed_values: {},
            selected_error_code: null,
            new_languages: [],
            add_error_code: false,
            new_error_code: "",
            selected_language: "",
            new_lan: ""
          })
        }
      })
    } else {
      xenionService.updateErrorCode(selected_error_code, changed_values, res => {
        if (res.result) {
          this.refresh()
          this.setState({
            changed_values: {},
            selected_error_code: null,
            new_languages: [],
            add_error_code: false,
            new_error_code: "",
            selected_language: "",
            new_lan: ""
          })
        }
      })
    }


  }

  add_language() {
    const {selected_language, new_languages, new_lan, error_codes, selected_error_code} = this.state

    let add_language = selected_language
    if (add_language === "other") {
      add_language = new_lan
    }

    if (add_language === "") {
      console.error("Trying to add empty language")
      return
    }
    if (new_languages.includes(add_language)) {
      console.error("Trying to add the language again")
      return
    }

    if (selected_error_code !== null) {
      let error_code = error_codes.find((ec) => {
        return ec.error_code === selected_error_code
      })
      let known_languages = Object.keys(error_code.languages)
      if (known_languages.includes(add_language)) {
        console.error("Trying to add the language again")
        return
      }
    }

    this.setState({new_languages: [...new_languages, add_language]})
  }

  render() {
    const {
      error_codes,
      chosen_language,
      languages,
      selected_error_code,
      new_languages,
      selected_language,
      new_lan,
      add_error_code,
      new_error_code
    } = this.state
    const {classes} = this.props

    let columns = [
      {
        Header: "Error Code",
        accessor: "error_code",
        maxWidth: 125,
        filterMethod: (filter, row) => filterSubstring(filter, row),
      },
      {
        Header: "Chosen Language",
        accessor: chosen_language,
        filterMethod: (filter, row) => filterSubstring(filter, row),
      },
      {
        Header: "Actions",
        accessor: "error_code",
        maxWidth: 100,
        filterable: false,
        sortable: false,
        Cell: row => {
          return <p>
            <Button justIcon round simple color={"primary"}
                    onClick={this.select_error_code.bind(this, row.value)}> <Edit/> </Button>
          </p>
        }
      }
    ]

    let error_code = {
      error_code: "NEW",
      languages: {},
      development: ""
    }
    if (selected_error_code !== null) {
      error_code = error_codes.find((ec) => {
        return ec.error_code === selected_error_code
      })
    }

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={!(selected_error_code !== null || add_error_code) ? 12 : 6}>
          <Card>
            <CardHeader><h4 style={{color: primaryColor}}><b>{"Error Codes"}</b></h4></CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <Button onClick={this.new_error_code.bind(this)} color={"primary"}>Add Error
                    Code</Button>
                  <Button onClick={this.download_error_codes.bind(this)} color={"primary"}>Download
                    PDF</Button>

                  <MyDropdown
                    input_label={"Choose Language"}
                    name={"language"}
                    value={chosen_language}
                    handle_selection={
                      this.handleLanguage.bind(this)
                    }
                    options={[
                      {
                        id: "development",
                        value: "Development"
                      },

                      ...languages.map((item) => {
                        return {
                          id: "languages." + item,
                          value: item
                        }
                      })
                    ]}
                  />
                </GridItem>
                <GridItem md={12}>
                  <MyReactTable
                    id={"device_config"}
                    data={error_codes}
                    filterable
                    columns={columns}
                    defaultPageSize={10}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    className=" -highlight"
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        {(selected_error_code !== null || add_error_code) &&
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader><h4 style={{color: primaryColor}}><b>Error
                Code: {error_code ? error_code.error_code : ""}</b></h4></CardHeader>
              <CardBody>
                <GridContainer>
                  {add_error_code &&
                    <GridItem md={12}>
                      <CustomInput
                        style={{
                          fullWidth: true,
                          width: "100%"
                        }}
                        id={"new_error_code"}
                        labelText={"New Error Code"}
                        inputProps={{
                          onChange: event => this.change_new_error_code(event.target.value),
                          type: "text",
                          value: new_error_code
                        }}
                      />
                    </GridItem>
                  }
                  <GridItem md={12}>
                    <Table
                      tableData={[
                        [<b>Language</b>, <b>Text</b>],
                        [
                          "dev",
                          <FormControl fullWidth className={classes.selectFormControl}>
                            <CustomInput
                              style={{
                                fullWidth: true,
                                width: "100%"
                              }}
                              fullWidth={true}
                              multiline={true}
                              id={"dev"}
                              labelText={""}
                              inputProps={{
                                onChange: event => this.change_value("dev", event.target.value),
                                type: "text",
                                value: "dev" in this.state.changed_values ? this.state.changed_values["dev"] : error_code.development,
                                fullWidth: true,
                                multiline: true
                              }}
                            />
                          </FormControl>
                        ],
                        ...Object.keys(error_code.languages).map((lan) => {
                          let value = lan in this.state.changed_values ? this.state.changed_values[lan] : error_code.languages[lan]
                          return [
                            lan,
                            <FormControl fullWidth className={classes.selectFormControl}>
                              <CustomInput
                                style={{
                                  fullWidth: true,
                                  width: "100%"
                                }}
                                fullWidth={true}
                                multiline={true}
                                id={lan}
                                labelText={""}
                                inputProps={{
                                  onChange: event => this.change_value(lan, event.target.value),
                                  type: "text",
                                  value: value,
                                  fullWidth: true,
                                  multiline: true
                                }}
                              />
                            </FormControl>
                          ]
                        }),
                        ...new_languages.map((lan) => {
                          let value = lan in this.state.changed_values ? this.state.changed_values[lan] : ""
                          return [
                            lan,
                            <FormControl fullWidth className={classes.selectFormControl}>
                              <CustomInput
                                style={{
                                  fullWidth: true,
                                  width: "100%"
                                }}
                                fullWidth={true}
                                multiline={true}
                                id={lan}
                                labelText={""}
                                inputProps={{
                                  onChange: event => this.change_value(lan, event.target.value),
                                  type: "text",
                                  value: value,
                                  fullWidth: true,
                                  multiline: true
                                }}
                              />
                            </FormControl>
                          ]
                        }),
                        ["", ""]
                      ]}/>

                    <Button onClick={this.edit.bind(this)} color={"primary"}> Confirm </Button>
                    <Button onClick={this.cancel.bind(this)} color={"warning"}> Cancel</Button>
                  </GridItem>
                  <GridItem md={12}>
                    <MyDropdown
                      input_label={"Add Language"}
                      name={"language"}
                      value={selected_language}
                      handle_selection={
                        this.handleAddLanguage.bind(this)
                      }
                      options={[
                        ...languages.filter((l) => {
                          if (error_code && error_code.languages) {
                            if (Object.keys(error_code.languages).includes(l)) {
                              return false
                            }
                          }
                          return true
                        }).map((item) => {
                          return {
                            id: item,
                            value: item
                          }
                        }),
                        {
                          id: "other",
                          value: "other"
                        }
                      ]}
                    />
                    {selected_language === "other" &&
                      <CustomInput
                        style={{
                          fullWidth: true,
                          width: "100%"
                        }}
                        id={"new_lan"}
                        labelText={"New Languages"}
                        inputProps={{
                          onChange: event => this.change_new_lan(event.target.value),
                          type: "text",
                          value: new_lan
                        }}
                      />
                    }

                    <Button onClick={this.add_language.bind(this)} color={"primary"}> Add new
                      language </Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        }
      </GridContainer>

    )
  }
}


function mapStateToProps(state) {
  return {}
}


export default withStyles(extendedTablesStyle)(connect(mapStateToProps)(ErrorCodes))
