import React from "react"
// @material-ui/core components
// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Card from "components/Card"
import CardBody from "components/CardBody"
import CardHeader from "components/CardHeader"

import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import {mapService} from "../../_services";
import Table from "../../components/Table";

import GreenhouseMap from "./GreenhouseMap";
import MyDropdown from "../../components/MyDropdown";
import queryString from "query-string";
import {Link} from "react-router-dom";
import NavPills from "../../components/NavPills";
import withStyles from "@material-ui/core/styles/withStyles";
import {connect} from "react-redux";
import extendedTablesStyle from "./extendedTablesStyle";
import Button from "../../components/CustomButtons";
import {sweetAlertActions} from "../../_actions";


class Maps extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      map_ids: [],
      map_id: undefined,
      map_versions: [],
      map_version: undefined,
      map_sync_information: {},


      map_detail: {},
      greenhouse: {},
      map_detail_list: [],
      gutter_version: undefined,
      obstacle_version: undefined,
    }

    this.timer = null
    this._is_mounted = false
    this._refresh_rate = 3000
  }

  componentDidMount() {
    this._is_mounted = true
    const {map_id, map_version} = queryString.parse(this.props.location.search)
    if (map_id) {
      this.setState({map_id, map_version}, () => {
        this.refresh()
      })
    } else {
      this.refresh()
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this._is_mounted = false
  }

  refresh() {
    const {map_id, map_version} = this.state

    mapService.getMapIds(result => {
      this.setState({map_ids: result.results})
    })
    mapService.getMapVersions(map_id, res => {
      this.setState({map_versions: res.versions})
    })
    if (map_id && map_version) {
      mapService.getMapSyncInfo(map_id, map_version, res => {
        this.setState({map_sync_information: res.result})
      })

      mapService.getGreenhouseByMapID(map_id, res => {
        this.setState({greenhouse: res.greenhouse})
      })
    }
    mapService.getMapDetail(map_id,
      res => {
        let map_detail_list = []
        let versions = [<b> Layers </b>]
        let raw_versions = []
        let empty = [""]

        let layer_names = []

        res.result["versions"].forEach((version_info) => {
          versions.push(<b> {version_info["version"]} </b>)
          raw_versions.push(version_info["version"])
          empty.push("")

          version_info["layers"].forEach((layer) => {
            if (!layer_names.includes(layer["name"])) {
              layer_names.push(layer["name"])
            }
          })
        })
        map_detail_list.push(versions)

        layer_names.forEach((layer_name) => {
          if (!(["sites", "nodes", "connections", "waypoints"].includes(layer_name))) {
            let layer_row = [layer_name]
            raw_versions.forEach((version) => {
              let temp_version_info = {}
              res.result["versions"].forEach((version_info) => {
                if (version_info["version"] === version) {
                  temp_version_info = version_info
                }
              })
              if (Object.keys(temp_version_info).includes("layers")) {
                temp_version_info["layers"].forEach((layer) => {
                  let layer_versions = layer["versions"].map((l_version) => {
                    return l_version.replace(version + '.', "")
                  })
                  if (layer["name"] === layer_name) {
                    layer_row.push(layer_versions.join(', '))
                  }
                })
              }
            })
            map_detail_list.push(layer_row)
          }
        })
        map_detail_list.push(empty)

        this.setState({
          map_detail: res.result,
          map_detail_list,
        })
      },
      reject => {
        this.setState({})
      })
  }

  handle_form_input_selector(name, value) {
    if (name === "map_version") {
      this.setState({gutter_version: undefined, obstacle_version: undefined, [name]: value}, () => this.refresh())
    } else {
      this.setState({[name]: value}, () => this.refresh())
    }
  }

  sync_map_to_continental() {
    const {dispatch} = this.props
    const {map_id, map_version} = this.state
    mapService.syncMap(map_id, map_version, res => {
      if (res.result) {
        dispatch(sweetAlertActions.success({title: "Map is synced to Continental FMC"}))
      } else {
        dispatch(sweetAlertActions.danger({title: "Something went wrong syncing to Continental FMC"}))
      }
    })
  }

  render() {
    const {nimbus_manager} = this.props
    const {
      map_detail_list,
      gutter_version,
      obstacle_version,
      map_detail,
      greenhouse,
      map_ids,
      map_id,
      map_versions,
      map_version,
      map_sync_information,
    } = this.state

    let gutter_versions = []
    let obstacle_versions = []

    if (map_detail !== {} && map_detail["versions"] !== undefined) {
      map_detail["versions"].forEach((version) => {
        if (version["version"] === map_version) {
          version["layers"].forEach((layer_versions) => {
            if (layer_versions["name"] === "gutters") {
              gutter_versions = layer_versions["versions"]
            }
            if (layer_versions["name"] === "obstacles") {
              obstacle_versions = layer_versions["versions"]
            }
          })
        }
      })
    }

    let map_detail_table = [
      ["Name", map_detail["map_name"]],
      ["Greenhouse Type", map_detail["greenhouse_type"]],
      ["Timezone", greenhouse ? greenhouse["timezone"] : "?"],
      ["Greenhouse Name", greenhouse ? greenhouse["name"] : "?"],
    ]
    if (window.CONTINENTAL_INTEGRATION_ENABLED) {
      map_detail_table.push([
        "Sync To Continental:",
        nimbus_manager ?
          map_sync_information !== undefined && map_sync_information !== null && map_sync_information["continental_currently_stored_at"] ?
            <b>Map is Synced</b> :
            <Button color={"primary"} onClick={this.sync_map_to_continental.bind(this)} simple
                    disabled={map_version && !map_version.startsWith("3")}> Sync </Button>
          :
          "Ask your cloud manager to sync this map to Continental"
      ])
    }
    map_detail_table.push(["", ""])

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              <h4 style={{color: primaryColor}}>
                <b>{map_detail["map_name"]}</b>
              </h4>
            </CardHeader>
            <CardBody>
              <Link to={`/maps/maps`}>To Overview </Link>
              <GridContainer>
                <GridItem xs={12} md={3}>
                  <MyDropdown
                    input_label={"Map ID"}
                    name={"map_id"}
                    value={map_id}
                    default_value={map_id}
                    options={[...map_ids.map((item) => {
                      return {id: item, value: item}
                    })]}
                    handle_selection={this.handle_form_input_selector.bind(this)}
                  />
                </GridItem>
                <GridItem xs={12} md={3}>
                  <MyDropdown
                    input_label={"Map Version"}
                    name={"map_version"}
                    value={map_version}
                    default_value={map_version}
                    options={[...map_versions.map((item) => {
                      return {id: item, value: item}
                    })]}
                    handle_selection={this.handle_form_input_selector.bind(this)}
                  />
                </GridItem>
                <GridItem xs={12} md={6}>
                  <Table
                    tableHeaderColor="primary"
                    tableData={map_detail_table}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <NavPills
                tabs={
                  [
                    {
                      tabButton: "Map View",
                      tabContent: <GridContainer>
                        <GridItem xs={12} md={6}>
                          <MyDropdown
                            input_label={"Gutter Version"}
                            name={"gutter_version"}
                            value={gutter_version}
                            default_value={gutter_version}
                            options={[{
                              id: undefined,
                              value: "No Gutters"
                            }, ...gutter_versions.map((item) => {
                              return {id: item, value: item}
                            })]}
                            handle_selection={this.handle_form_input_selector.bind(this)}
                          />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                          <MyDropdown
                            input_label={"Obstacle Version"}
                            name={"obstacle_version"}
                            value={obstacle_version}
                            default_value={obstacle_version}
                            options={[{
                              id: undefined,
                              value: "No Obstacles"
                            }, ...obstacle_versions.map((item) => {
                              return {id: item, value: item}
                            })]}
                            handle_selection={this.handle_form_input_selector.bind(this)}
                          />
                        </GridItem>
                        <GridItem xs={12} md={12}>
                          <GreenhouseMap
                            map_id={map_id}
                            map_version={map_version}
                            gutter_layer_version={gutter_version}
                            obstacle_layer_version={obstacle_version}
                          />
                        </GridItem>
                      </GridContainer>
                    },
                    {
                      tabButton: "Map Layers & Versions",
                      tabContent: <div>
                        <Table
                          color={"primary"}
                          tableData={map_detail_list}
                        />
                      </div>
                    },
                  ]
                }
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}


function mapStateToProps(state) {
  const {nimbus_manager} = state.authentication.user
  return {
    nimbus_manager
  }
}


export default withStyles(extendedTablesStyle)(connect(mapStateToProps)(Maps))
