import React from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// Assets
import userProfileStyles from "./userProfileStyles.jsx"
import CardHeader from "../../components/CardHeader";
import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import GridContainer from "../../components/GridContainer";
import GridItem from "../../components/GridItem";
import Card from "../../components/Card";
import CardBody from "../../components/CardBody";
import {managementService} from "../../_services";
import {filterSubstring} from "../helper_functions/table_filtering";
import MyReactTable from "../../components/MyReactTable/MyReactTable";
import {connect} from "react-redux";
import {sweetAlertActions} from "../../_actions";
import CustomInput from "../../components/CustomInput";
import Button from "../../components/CustomButtons";

class DeviceTypes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      all_device_types: [],
      updated_properties: {}
    }
    this._is_mounted = false
    this.timer = null
  }

  componentDidMount() {
    this._is_mounted = true
    this.refresh()
  }

  refresh() {
    managementService.getAllDeviceTypes(res => {
      this.setState({all_device_types: res.result})
    })
  }

  change_value(value_name, value) {
    let {updated_properties} = this.state
    updated_properties[value_name] = value
    this.setState({updated_properties})
  }

  componentWillUnmount() {
    this._is_mounted = false
    clearTimeout(this.timer)
    this.timer = null
  }

  start_updating_device_type(device_type_id, device_type) {
    const {dispatch} = this.props
    const {updated_properties} = this.state

    dispatch(sweetAlertActions.clean({
      title: "Update Device Type",
      showCancel: true,
      onCancel: this.clear_popup.bind(this),
      cancelBtnText: "No",
      onConfirm: this.update_device_type.bind(this, device_type_id, device_type),
      confirmBtnText: "Yes",
      content: <div>

        <p><b>Leave empty if you do not want to update the property</b></p>
        <p><b>Name:</b> {device_type["name"]}</p>
        <CustomInput
          style={{
            fullWidth: true,
            width: "100%"
          }}
          id={"name"}
          labelText={"New name"}
          inputProps={{
            onChange: event => this.change_value("name", event.target.value),
            type: "text",
            value: updated_properties["name"]
          }}
        />
      </div>
    }))
  }

  update_device_type(device_type_id, device_type) {
    const {updated_properties} = this.state
    const {dispatch} = this.props;

    let new_name = undefined
    if (updated_properties["name"] !== undefined) {
      if (updated_properties["name"] !== device_type["name"]) {
        new_name = updated_properties["name"]
      }
    }
    managementService.updateDeviceType(device_type_id, new_name, res => {
      dispatch(sweetAlertActions.clear())
      this.setState({
        updated_properties: {}
      }, () => this.refresh())
    })
  }

  clear_popup() {
    const {dispatch} = this.props;
    dispatch(sweetAlertActions.clear())
    this.setState({
      updated_properties: {}
    }, () => this.refresh())
  }

  render() {
    const {all_device_types} = this.state

    let columns = [
      {
        Header: "ID",
        accessor: "id",
        filterMethod: (filter, row) => filterSubstring(filter, row),
      },
      {
        Header: "Name",
        accessor: "name",
        filterMethod: (filter, row) => filterSubstring(filter, row),
      }]
    columns.push({
      Header: "Actions",
      accessor: "id",
      Cell: row => {
        return <Button
          color={"primary"}
          onClick={this.start_updating_device_type.bind(this, row.value, row.original)}
          simple
        >
          Update Device Type
        </Button>
      }
    })

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader><h4 style={{color: primaryColor}}><b> Device Types </b></h4></CardHeader>
            <CardBody>
              <MyReactTable
                id={"all_device_types"}
                data={all_device_types}
                filterable
                columns={columns}
                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom={true}
                className=" -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}


function mapStateToProps(state) {
  const {all_devices} = state.device_fleet
  const {nimbus_manager} = state.authentication.user
  return {
    all_devices, nimbus_manager
  }
}

export default withStyles(userProfileStyles)(connect(mapStateToProps)(DeviceTypes))
