import React from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// Assets
import userProfileStyles from "./userProfileStyles.jsx"
import CardHeader from "../../components/CardHeader";
import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import GridContainer from "../../components/GridContainer";
import GridItem from "../../components/GridItem";
import Card from "../../components/Card";
import CardBody from "../../components/CardBody";
import {xenionService} from "../../_services";
import Button from "../../components/CustomButtons";
import CustomInput from "../../components/CustomInput";
import {connect} from "react-redux";
import DeviceDropdown from "../helper_functions/DeviceDropdown";
import {beautify_name} from "../helpers";
import {sweetAlertActions} from "../../_actions";

class DeviceDeployment extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected_id: null,
      new_deployment: {},
      current_deployment: {}
    }
    this._is_mounted = false
    this.timer = null
  }

  componentDidMount() {
    this._is_mounted = true
  }

  setDeployment() {
    const {device_serial, dispatch} = this.props
    const {new_deployment} = this.state

    let deployment_to_be_updated = {}
    Object.keys(new_deployment).forEach((deployment_type) => {
      if (this.get_current_deployment(deployment_type, "username") === "***" && this.get_current_deployment(deployment_type, "password") === "***") {
        deployment_to_be_updated[deployment_type] = new_deployment[deployment_type]
      } else if (
        this.get_current_deployment(deployment_type, "username") !== new_deployment[deployment_type]["username"]
        ||
        this.get_current_deployment(deployment_type, "password") !== new_deployment[deployment_type]["password"]
      ) {
        deployment_to_be_updated[deployment_type] = new_deployment[deployment_type]
      }
    })

    if (Object.keys(deployment_to_be_updated).length !== 0) {
      xenionService.updateDeploymentInformation(device_serial, deployment_to_be_updated, res => {
        dispatch(sweetAlertActions.success({title: `The credentials are updated for ${Object.keys(deployment_to_be_updated).join(", ")}!`}))
        this.setState({current_deployment: {}, new_deployment: {}})
      })
    } else {
      dispatch(sweetAlertActions.warning({title: `No changes are made.`}))
    }
  }

  componentWillUnmount() {
    this._is_mounted = false
    clearTimeout(this.timer)
    this.timer = null
  }

  handle_form_input_selector(name, value) {
    this.setState({[name]: value, new_deployment: {}})
  }

  handle_input(deployment_type, name, value) {
    const {new_deployment} = this.state

    let adapted_deployment = {
      ...new_deployment,
      [deployment_type]: {...new_deployment[deployment_type], [name]: value}
    }
    if (value === "") {
      delete adapted_deployment[deployment_type][name]
    }
    this.setState({new_deployment: adapted_deployment})
  }

  set_current(deployment_type, name, value) {
    const {current_deployment} = this.state

    let adapted_deployment = {
      ...current_deployment,
      [deployment_type]: {...current_deployment[deployment_type], [name]: value}
    }
    if (value === "") {
      delete adapted_deployment[deployment_type][name]
    }
    this.setState({current_deployment: adapted_deployment})
  }

  get_new_deployment(deployment_type, name) {
    const {new_deployment} = this.state

    if (new_deployment[deployment_type] === undefined || new_deployment[deployment_type] === null) {
      return ""
    }

    if (new_deployment[deployment_type][name] === undefined || new_deployment[deployment_type][name] === null) {
      return ""
    }

    return new_deployment[deployment_type][name]
  }

  get_current_deployment(deployment_type, name) {
    const {current_deployment} = this.state

    if (current_deployment[deployment_type] === undefined || current_deployment[deployment_type] === null) {
      return "***"
    }

    if (current_deployment[deployment_type][name] === undefined || current_deployment[deployment_type][name] === null) {
      return "***"
    }

    return current_deployment[deployment_type][name]
  }

  request_deployment_info(deployment_type) {
    const {device_serial} = this.props
    xenionService.getDeploymentInformation(device_serial, deployment_type, res => {
      this.handle_input(deployment_type, "username", res.username)
      this.handle_input(deployment_type, "password", res.password)
      this.set_current(deployment_type, "username", res.username)
      this.set_current(deployment_type, "password", res.password)
    })
  }

  render() {
    const {all_devices, device_serial} = this.props

    let selected_device_name = ""
    all_devices.forEach((device) => {
      if (device.serial === device_serial) {
        selected_device_name = device.name
      }
    })

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={5}>
          <Card>
            <CardHeader><h4 style={{color: primaryColor}}><b>Update SSH Credentials
              for {selected_device_name}</b></h4></CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <DeviceDropdown/>
                </GridItem>
                {["pi", "nuc", "router"].map((deployment_type) => {
                  return (
                    <GridItem xs={12}>
                      <h5 style={{color: primaryColor}}>SSH
                        Credentials {beautify_name(deployment_type)}</h5>
                      <GridContainer>
                        <GridItem xs={4}>
                          Current Username: {this.get_current_deployment(deployment_type, "username")} <br/>
                          <CustomInput
                            style={{
                              fullWidth: true,
                              width: "100%"
                            }}
                            id={`${deployment_type}_username`}
                            labelText={"New Username"}
                            inputProps={{
                              onChange: event => this.handle_input(deployment_type, "username", event.target.value),
                              type: "text",
                              value: this.get_new_deployment(deployment_type, "username")
                            }}
                          />
                        </GridItem>
                        <GridItem xs={4}>
                          Current Password: {this.get_current_deployment(deployment_type, "password")} <br/>
                          <CustomInput
                            style={{
                              fullWidth: true,
                              width: "100%"
                            }}
                            id={`${deployment_type}_password`}
                            labelText={"New Password"}
                            inputProps={{
                              onChange: event => this.handle_input(deployment_type, "password", event.target.value),
                              type: "text",
                              value: this.get_new_deployment(deployment_type, "password")
                            }}
                          />
                        </GridItem>
                        <GridItem xs={4}>
                          <Button round simple color={"primary"}
                                  onClick={this.request_deployment_info.bind(this, deployment_type)}>
                            Request Information
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  )
                })}
                <GridItem xs={12}>
                  <Button color={"primary"} onClick={this.setDeployment.bind(this)}>
                    Update SSH Credentials
                  </Button>
                </GridItem>
              </GridContainer>

            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={7}>
          <Card>
            <CardHeader><h4 style={{color: primaryColor}}><b>Audit Log</b></h4></CardHeader>
            <CardBody>
              Coming Soon: audit log
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}

function mapStateToProps(state) {
  const {all_devices, device_serial} = state.device_fleet
  return {
    all_devices, device_serial
  }
}

export default withStyles(userProfileStyles)(connect(mapStateToProps)(DeviceDeployment))