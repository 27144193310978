import {userConstants} from '_constants'

const initialState = {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user,
      };
    case userConstants.UPDATE_USER:
      return {
        ...state,
        loggedIn: action.user? true : false,
        user: action.user,
      };
    case userConstants.UPDATE_NAME:
      return {...state, user: {...state.user, first_name: action.first_name, name: action.lastname}};
    default:
      return state
  }
}

export function updateUserState(state = initialState, action) {
  switch (action.type) {
    case userConstants.UPDATE_USER:
      return {
        ...state,
        loggedIn: action.user? true : false,
        user: action.user,
      };
    default:
      return state;
  }
}