import {deviceFleetConstants} from '_constants'

const initialState = {
  all_devices: [],
  all_commissioned_devices: [],
  error_codes: {},
  device_state: {},
  device_data: {},
};

export function device_fleet(state = initialState, action) {
  switch (action.type) {
    case deviceFleetConstants.UPDATE_ALL_DEVICES:
      return {
        ...state,
        all_devices: action.all_devices,
      };
    case deviceFleetConstants.UPDATE_ALL_COMMISSIONED_DEVICES:
      return {
        ...state,
        all_commissioned_devices: action.all_commissioned_devices,
      };
    case deviceFleetConstants.UPDATE_DEVICE_SERIAL:
      return {
        ...state,
        device_serial: action.device_serial,
      };
    case deviceFleetConstants.UPDATE_ERROR_CODES:
      return {
        ...state,
        error_codes: {
          ...state.error_codes,
          ...action.error_codes
        }
      };
    case deviceFleetConstants.UPDATE_DEVICE_STATE:
      return {
        ...state,
        device_state: {
          ...state.device_state,
          ...action.device_state
        }
      };
    case deviceFleetConstants.UPDATE_DEVICE_DATA:
      return {
        ...state,
        device_data: {
          ...state.device_data,
          [action.device_serial]: {
            ...state.device_data[action.device_serial],
            ...action.device_data
          }
        }
      };
    default:
      return state
  }
}
