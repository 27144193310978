import React from "react"
import {connect} from 'react-redux'
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Card from "components/Card"
import CardBody from "components/CardBody"

import extendedTablesStyle from "./extendedTablesStyle.jsx"

import {dangerColor, primaryColor} from "../../assets/jss/material-dashboard-pro-react";

import ReactTimeAgo from 'react-time-ago'
import {systemService, treatmentsService} from "../../_services";
import {Link} from "react-router-dom";
import {lumion_fix_in_treatment, numberWithSpaces} from "../helpers";
import Table from "../../components/Table";


class Dashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      last_updated: null,
      metrics_last_updated: null,
      treatments_last_updated: null,
      devices_online: [],
      devices_in_error: [],
      devices_in_treatment: [],
      devices_total: [],
      mileage_total: null,
      laneswitches_total: null,
      treatments_last_day: [],
      from_dt: "",
      to_dt: ""
    }

    this.timer = null
    this._is_mounted = false
    this._refresh_rate = 3000
  }

  componentDidMount() {
    this._is_mounted = true
    this.setState({loading: true})

    treatmentsService.getTreatmentsLastDay(result => {
      this.setState({
        loading: false,
        treatments_last_day: result["result"],
        from_dt: new Date(result["from_dt"]),
        to_dt: new Date(result["to_dt"]),
        treatments_last_updated: new Date()
      })
    })
    this.refresh()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this._is_mounted = false
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.device_serial !== this.props.device_serial) {
      this.refresh(this.props.device_serial)
    }
    if (prevProps && prevProps.device_state  !== this.props.device_state) {
      this.refresh_websocket_state(this.props.device_state)
    }
  }

  refresh_websocket_state(device_state){
    const {all_commissioned_devices} = this.props

    let devices_online = []
    let devices_in_error = []
    let devices_in_treatment = []
    let devices_total = []

    all_commissioned_devices.forEach(device =>{
      if (device["serial"] in device_state){
        devices_online.push(device["serial"])
        let state = device_state[device["serial"]]
        if(device["device_type"] === "LUMION_FIX"){
          if (state["state"]["current_state"] === "ERROR"){
            devices_in_error.push(device["serial"])
          } else if (lumion_fix_in_treatment(state["state"]["current_state"], state["state"]["current_task"])){
            devices_in_treatment.push(device["serial"])
          }

        } else if (device["device_type"] === "DIV01"){
          if (state["state"]["state"] === "error"){
            devices_in_error.push(device["serial"])
          } else if (state["state"]["state"] !== "idle"){
            devices_in_treatment.push(device["serial"])
          }
          // FIXME: add check of state in treatment
        } else if (device["device_type"] === "KOMPANO"){
          console.log(state)
          // FIXME: add check of state in treatment

        } else if (device["device_type"] === "UVP01"){
          console.log(state)
          // FIXME: add check of state in treatment

        } else if (device["device_type"] === "UVP02"){
          console.log(state)
          // FIXME: add check of state in treatment

        } else if (device["device_type"] === "DSS01"){
          console.log(state)
          // FIXME: add check of state in treatment

        }
      }
      devices_total.push(device["serial"])
    })

    this.setState({
      last_updated: new Date(),
      devices_online,
      devices_in_error,
      devices_in_treatment,
      devices_total
    })

  }

  refresh() {
    const {device_state} = this.props
    this.refresh_websocket_state(device_state)
    systemService.getTotalMetrics(
      result => {
        this.setState({
          mileage_total: Math.round(result.total_mileage / 1000, 2),
          laneswitches_total: result.total_laneswitches,
          metrics_last_updated: new Date(result.time)
        })
      },
    )

    if (this._is_mounted) {
      this.timer = setTimeout(() => this.refresh(), this._refresh_rate)
    }
  }

  render() {
    const {deployments, locations} = this.props
    const {
      devices_online,
      devices_in_error,
      devices_in_treatment,
      devices_total,
      last_updated,
      laneswitches_total,
      mileage_total,
      metrics_last_updated,
      treatments_last_day,
      from_dt,
      to_dt
    } = this.state

    let deployments_per_device = {}
    if (deployments) {
      Object.keys(deployments).forEach((deployment_id) => {
        let deployment = deployments[deployment_id]
        deployments_per_device[deployment["device_serial"]] = deployment["location_id"]
      })
    }

    let previous_location_name = ""
    let previous_device_name = ""

    let treatments_last_day_extended = []
    treatments_last_day.map((treatment) => {
      let location = {"name": "loading..."}
      if (locations && deployments && deployments_per_device) {
        if (treatment["machine_serial"] in deployments_per_device) {
          location = locations[deployments_per_device[treatment["machine_serial"]]]
        } else {
          location = {"name": "Not Deployed..."}
        }
      }
      return {
        ...treatment,
        location: location ? location["name"] : "Not Deployed..."
      }
    }).sort((a, b) => {
      if (a.location === b.location) {
        if (a.device_name === b.device_name) {
          if (a.start_time > b.start_time) return 1;
          if (a.start_time < b.start_time) return -1;
          return 0;
        }
        if (a.device_name > b.device_name) return 1;
        if (a.device_name < b.device_name) return -1;
        return 0;
      }

      if (a.location === "Not Deployed...") return 1
      if (b.location === "Not Deployed...") return -1
      if (a.location > b.location) return 1;
      if (a.location < b.location) return -1;
      return 0;
    }).forEach((t) => {
      let location_name = t["location"] === previous_location_name ? "" : t["location"]
      let device_name = t["device_name"] === previous_device_name ? "" : t["device_name"]
      previous_location_name = t["location"]
      previous_device_name = t["device_name"]

      treatments_last_day_extended.push({
        ...t,
        location: location_name,
        device_name: device_name
      })
    })

    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={8}>
                  <h4 style={{color: primaryColor}}>
                    <b>{"Devices in Error"}</b> {last_updated !== null ?
                    <p>Updated: <ReactTimeAgo date={last_updated} locale="en-UK"/></p> : ""}
                  </h4>
                  <Link to={`/fleet/state?filter_device_serial=${devices_in_error.join(";")}`}>To
                    Overview </Link>
                </GridItem>
                <GridItem xs={4} style={{textAlign: "right",}}>
                  <h1 style={{color: devices_in_error.length > 0 ? dangerColor : "black"}}>
                    <span> {devices_in_error.length} </span>
                    <span style={{fontSize: "0.5em"}}>/ {devices_online.length}</span>
                  </h1>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={8}>
                  <h4 style={{color: primaryColor}}>
                    <b>{"Devices in Treatment"}</b>
                    {last_updated !== null ?
                      <p>Updated: <ReactTimeAgo date={last_updated} locale="en-UK"/></p> : ""}
                  </h4>
                  <Link to={`/fleet/state?filter_device_serial=${devices_in_treatment.join(";")}`}>To
                    Overview </Link>
                </GridItem>
                <GridItem xs={4} style={{textAlign: "right"}}>
                  <h1>
                    <span> {devices_in_treatment.length} </span>
                    <span style={{fontSize: "0.5em"}}>/ {devices_online.length}</span>
                  </h1>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={8}>
                  <h4 style={{color: primaryColor}}>
                    <b>{"Online Devices"}</b> {last_updated !== null ?
                    <p>Updated: <ReactTimeAgo date={last_updated} locale="en-UK"/></p> : ""}
                  </h4>
                </GridItem>
                <GridItem xs={4} style={{textAlign: "right",}}>
                  <h1>
                    <span> {devices_online.length} </span>
                    <span style={{fontSize: "0.5em"}}>/ {devices_total.length}</span>
                  </h1>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={5}>
                  <h4 style={{color: primaryColor}}>
                    <b>{"Mileage"}</b> {metrics_last_updated !== null ?
                    <p>Updated: <ReactTimeAgo date={metrics_last_updated} locale="en-UK"/></p> : ""}
                  </h4>
                </GridItem>
                <GridItem xs={7} style={{textAlign: "right",}}>
                  <h2> {mileage_total ? numberWithSpaces(mileage_total) : ""} km</h2>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={5}>
                  <h4 style={{color: primaryColor}}>
                    <b>{"Lane Switches"}</b> {metrics_last_updated !== null ?
                    <p>Updated: <ReactTimeAgo date={metrics_last_updated} locale="en-UK"/></p> : ""}
                  </h4>
                </GridItem>
                <GridItem xs={7} style={{textAlign: "right",}}>
                  <h2> {laneswitches_total ? numberWithSpaces(laneswitches_total) : ""} </h2>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <h4 style={{color: primaryColor}}>
                <b>{"Treatment overview of the last day"}</b>
                {from_dt !== null ? <p>From: {from_dt.toLocaleString('nl-BE')}</p> : ""}
                {to_dt !== null ? <p>To: {to_dt.toLocaleString('nl-BE')}</p> : ""}
              </h4>
              <Table
                color={"primary"}
                tableData={[
                  [<b>Location</b>, <b>Device Serial</b>, <b>Start Time</b>, <b>Finish Status</b>,
                    <b>Lanes Treated / Lanes Asked</b>,
                    <b>Coverage</b>,
                    <b>Reached End</b>, <b>To Treatment</b>],
                  ...treatments_last_day_extended.map((treatment) => {
                    let finish_status = <span>{treatment["finish_status"]}</span>
                    if (treatment["finish_status"] === "fully_completed") {
                      finish_status =
                        <span style={{color: "rgb(100,169,68)"}}>Fully Completed</span>
                    }
                    if (treatment["finish_status"] === "partially_completed") {
                      finish_status =
                        <span style={{color: "rgb(199,159,25)"}}>Partially Completed</span>
                    }
                    if (treatment["finish_status"] === "stopped") {
                      finish_status = <span style={{color: "rgb(199,159,25)"}}>Stopped</span>
                    }
                    if (treatment["finish_status"] === "paused") {
                      finish_status = <span style={{color: "rgb(199,159,25)"}}>Paused</span>
                    }
                    if (treatment["finish_status"] === "failed") {
                      finish_status = <span style={{color: "rgb(231,92,92)"}}>Failed</span>
                    }
                    let reached_end = <span style={{color: "rgb(114,114,114)"}}>-</span>
                    let asked_treated = <span style={{color: "rgb(114,114,114)"}}>-</span>

                    let coverage = "Unknown"
                    if (treatment["stats"] !== undefined && treatment["stats"] !== null) {
                      coverage = Math.round(((treatment["stats"]["coverage"] * 100) + Number.EPSILON) * 100) / 100 + "%"

                      reached_end = <span
                        style={{color: treatment["stats"]["reached_end"] ? "rgb(100,169,68)" : "rgb(231,92,92)"}}>
                                                    {treatment["stats"]["reached_end"] ? "Yes" : "No"}
                                                </span>

                      asked_treated =
                        <span> {treatment["stats"]["total_treated"] - treatment["stats"]["total_failed"]} / {treatment["stats"]["total_treated"]}</span>
                    }

                    return [
                      treatment["location"],
                      treatment["device_name"],
                      treatment["start_time"] === null ?
                        <i>{new Date(new Date(treatment["creation_time"]).getTime() + (treatment["settings"]["delay"] * 1000)).toLocaleString("nl-BE")} (estimated)</i>
                        :
                        new Date(treatment["start_time"]).toLocaleString("nl-BE"),
                      finish_status,
                      asked_treated,
                      coverage,
                      reached_end,
                      <Link to={`/treatments/detail?treatment_id=${treatment["id"]}`}>To
                        Treatment</Link>
                    ]
                  }),
                  ["", "", "", "", "", "", ""]
                ]}/>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}


function mapStateToProps(state) {
  const {all_devices, all_commissioned_devices, device_state} = state.device_fleet
  const {deployments, locations} = state.location
  return {
    all_devices, deployments, locations, all_commissioned_devices, device_state
  }
}


export default withStyles(extendedTablesStyle)(connect(mapStateToProps)(Dashboard))
