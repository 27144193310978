import React from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"

// Assets
import userProfileStyles from "./userProfileStyles.jsx"

// Services
import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import Table from "../../components/Table";
import {get_connection_icon} from "../helper_functions/device_icons";
import ReactTimeAgo from "react-time-ago";
import {connect} from "react-redux";
import {deviceService} from "../../_services";
import {Alert} from "@mui/material";


class DockingImplementDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      last_websocket_connection: null,
      is_live: false,
      error_code: 0,
      di_state: "",
      implement_error: "",
      implement_error_code: 0,
      implement_functional: false,
      implement_imp_state: "",
      implement_implement: "",
      implement_implement_left: "",
      implement_implement_type: "",
      implement_implement_type_left: "",
      implement_sideshift: "",
      implement_state: "",
      rp_state: "",
      state: "",
      conti_rp_state: {},
      last_conti_update: null
    }

    this.timer = null
    this._is_mounted = false
    this._refresh_rate = 3000
  }

  componentDidMount() {
    this._is_mounted = true
    const {device_serial} = this.props
    this.refresh(device_serial)
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this._is_mounted = false
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.device_serial !== this.props.device_serial) {
      this.refresh(this.props.device_serial)
    }
    if (prevProps && prevProps.device_state && (prevProps.device_state[prevProps.device_serial] !== this.props.device_state[this.props.device_serial])) {
      this.refresh_websocket_state(this.props.device_state[this.props.device_serial])
    }
    if (prevProps && prevProps.device_data && (prevProps.device_data[prevProps.device_serial] !== this.props.device_data[this.props.device_serial])) {
      this.refresh_websocket_data(this.props.device_data[this.props.device_serial])
    }
  }

  refresh_websocket_data(websocket_data) {
    let state_to_update = {}
    this.setState(state_to_update)
  }

  refresh_websocket_state(websocket_state) {
    this.setState({
      error_code: websocket_state["state"]["error"],
      last_websocket_connection: websocket_state["timestamp"],
      di_state: websocket_state["state"]["di_state"],
      implement_error: websocket_state["state"]["implement_error"],
      implement_error_code: websocket_state["state"]["implement_error_code"],
      implement_functional: websocket_state["state"]["implement_functional"],
      implement_imp_state: websocket_state["state"]["implement_imp_state"],
      implement_implement: websocket_state["state"]["implement_implement"],
      implement_implement_left: websocket_state["state"]["implement_implement_left"],
      implement_implement_type: websocket_state["state"]["implement_implement_type"],
      implement_implement_type_left: websocket_state["state"]["implement_implement_type_left"],
      implement_sideshift: websocket_state["state"]["implement_sideshift"],
      implement_state: websocket_state["state"]["implement_state"],
      rp_state: websocket_state["state"]["rp_state"],
      state: websocket_state["state"]["state"],
    })
  }

  refresh(device_serial) {
    deviceService.getDeviceRpState(device_serial, res => {
      console.log(res)
      this.setState({
        conti_rp_state: res.state,
        last_conti_update: res.state.last_update,
      })
    })
    deviceService.getDeviceRawState(device_serial, res => {
      let state = res.state

      this.setState({
        is_live: state.is_live,
        error_code: state["state"]["error_code"],
        last_refresh: state["timestamp"],
        di_state: state["state"].di_state,
        implement_error: state["state"].implement_error,
        implement_error_code: state["state"].implement_error_code,
        implement_functional: state["state"].implement_functional,
        implement_imp_state: state["state"].implement_imp_state,
        implement_implement: state["state"].implement_implement,
        implement_implement_left: state["state"].implement_implement_left,
        implement_implement_type: state["state"].implement_implement_type,
        implement_implement_type_left: state["state"].implement_implement_type_left,
        implement_sideshift: state["state"].implement_sideshift,
        implement_state: state["state"].implement_state,
        rp_state: state["state"].rp_state,
        state: state["state"].state,
      })
    })

    if (this._is_mounted) {
      setTimeout(this.refresh.bind(this, device_serial), 5000)
    }
  }


  round_numbers(value) {
    return Math.round((value + Number.EPSILON) * 100) / 100
  }

  render() {
    const {device_serial, classes, device_name} = this.props
    const {
      is_live, has_wifi, last_websocket_connection, error_code, di_state, implement_error,
      implement_error_code, implement_functional, implement_imp_state, implement_implement, implement_implement_left,
      implement_implement_type, implement_implement_type_left, implement_sideshift, implement_state, rp_state,
      state, last_conti_update, conti_rp_state
    } = this.state

    return (
      <GridContainer>
        <GridItem xs={12} md={6}>
          <h5 style={{color: primaryColor}}>General</h5>
          <Table
            color={"primary"}
            tableData={[
              [
                <b>Serial Number</b>,
                device_serial
              ],
              [
                <b>Name</b>,
                <div>
                  {
                    is_live &&
                    has_wifi &&
                    get_connection_icon(classes, is_live, last_websocket_connection, has_wifi)
                  }
                  &nbsp; &nbsp;
                  {device_name}
                </div>
              ],
              [
                <b>Last Connection</b>,
                last_websocket_connection ? (
                  <span>
                                        {new Date(Date.parse(last_websocket_connection)).toLocaleString('nl-BE')}
                    &nbsp;&nbsp;(&nbsp;<ReactTimeAgo date={last_websocket_connection} locale="en-UK"/>&nbsp;)
                                        </span>
                ) : "Never connected"
              ],
              ["", ""]
            ]}/>
          <h5 style={{color: primaryColor}}>State</h5>
          <p> Updated: {last_websocket_connection ?
            <ReactTimeAgo date={last_websocket_connection} locale="en-UK"/> : "?"}</p>
          <Table
            color={"primary"}
            tableData={[
              [<b>State</b>, "General State", state],
              ["", "Robot Platform", rp_state],
              ["", "Docking Implement", di_state],
              ["", "Error Code", error_code],
              [<b>Docking State</b>, "", implement_state],
              [<b>Implement</b>, "Functional", implement_functional],
              ["", "Error", implement_error],
              ["", "Error Code", implement_error_code],
              [<b>SideShift</b>, "Serial", implement_sideshift],

              [<b>Implement One</b>, "Serial Number", implement_implement],
              ["", "Type", implement_implement_type],
              ["", "State", implement_imp_state],

              [<b>Implement Two</b>, "Serial Number", implement_implement_left],
              ["", "Type", implement_implement_type_left],

              ["", "", ""]
            ]}/>
        </GridItem>
        <GridItem xs={12} md={6}>
          <h5 style={{color: primaryColor}}>Robot Platform</h5>
          <p> Updated: {last_conti_update ?
            <ReactTimeAgo date={last_conti_update} locale="en-UK"/> : "?"}</p>
          {window.CONTINENTAL_INTEGRATION_ENABLED ?
            <Table
              color={"primary"}
              tableData={[
                [
                  <b>Last Seen</b>,
                  "",
                  conti_rp_state["last_seen"] ? (
                    <span> {new Date(Date.parse(conti_rp_state["last_seen"])).toLocaleString('nl-BE')}
                      &nbsp;&nbsp;(&nbsp;<ReactTimeAgo date={conti_rp_state["last_seen"]}
                                                       locale="en-UK"/>&nbsp;) </span>
                  ) : ""
                ],
                [
                  <b>Vehicle State</b>,
                  "state",
                  conti_rp_state["vehicle_state"]
                ],
                [
                  "",
                  "EStop Status",
                  conti_rp_state["software_estop_status"]
                ],
                [
                  <b>Location</b>,
                  "x",
                  conti_rp_state["pose"] && conti_rp_state["pose"]["position"] ? (
                    this.round_numbers(conti_rp_state["pose"]["position"]["x"])
                  ) : ""
                ],
                [
                  "",
                  "y",
                  conti_rp_state["pose"] && conti_rp_state["pose"]["position"] ? (
                    this.round_numbers(conti_rp_state["pose"]["position"]["y"])
                  ) : ""
                ],
                [
                  <b>Current Mission</b>,
                  "Mission ID",
                  conti_rp_state["current_mission_info"] ? (
                    conti_rp_state["current_mission_info"]["mission_id"]
                  ) : ""
                ],
                [
                  "",
                  "Execution State",
                  conti_rp_state["current_mission_info"] ? (
                    conti_rp_state["current_mission_info"]["execution_status"]
                  ) : ""
                ],
                [
                  "",
                  "Elapsed Time",
                  conti_rp_state["current_mission_info"] ? (
                    conti_rp_state["current_mission_info"]["elapsed_seconds"] + " s"
                  ) : ""
                ],
                [
                  "",
                  "Time Remaining",
                  conti_rp_state["current_mission_info"] ? (
                    conti_rp_state["current_mission_info"]["expected_completion_seconds"] + " s"
                  ) : ""
                ],
                [
                  <b>Battery</b>,
                  "State",
                  conti_rp_state["battery_state"] ? (
                    conti_rp_state["battery_state"]["status"]
                  ) : ""
                ],
                [
                  "",
                  "Percentage",
                  conti_rp_state["battery_state"] ? (
                    this.round_numbers(conti_rp_state["battery_state"]["percentage"]) + " %"
                  ) : ""
                ],
                [
                  "",
                  "Voltage",
                  conti_rp_state["battery_state"] ? (
                    this.round_numbers(conti_rp_state["battery_state"]["voltage"]) + " V"
                  ) : ""
                ],
                [
                  "",
                  "Current",
                  conti_rp_state["battery_state"] ? (
                    this.round_numbers(conti_rp_state["battery_state"]["current"]) + " A"
                  ) : ""
                ],
                [
                  <b>Network State</b>,
                  "Connection Type",
                  conti_rp_state["network_state"] ? (
                    conti_rp_state["network_state"]["connection_type"]
                  ) : ""
                ],
                [
                  <b>Metrics</b>,
                  "Lane Switch Count",
                  conti_rp_state["lane_switch_count"]
                ],
                [
                  "",
                  "Mileage",
                  conti_rp_state["mileage_meters"] + " m"
                ],
                [
                  "",
                  "Uptime",
                  conti_rp_state["uptime_seconds"] + " s"
                ],
                ["", "", ""]
              ]}
            /> :
            <Alert severity={"warning"}>The integration with Continental is disabled, for more information contact your
              Nimbus Developer</Alert>
          }
        </GridItem>
      </GridContainer>
    )
  }
}

function mapStateToProps(state) {
  const {device_serial, all_devices, device_state} = state.device_fleet

  let device_name = ""
  all_devices.forEach((device) => {
    if (device["serial"] === device_serial) {
      device_name = device["name"]
    }
  })

  return {
    device_serial, device_name, device_state
  }
}


export default withStyles(userProfileStyles)(connect(mapStateToProps)(DockingImplementDetail))
