import SuperAgent from 'superagent'
import {api_root} from 'index.js'
import {errorHandling, unauthorizedRedirect} from './error.handler.js'
import {getBearerAccessToken} from "./authenticate";

export const incidentService = {
  getAll,
  getPerMap,
  getPerDeviceSerial,
  getPerErrorCode,
  getIncidentRecord,
  downloadFile,
  retryPostProcessing,
  exportFile
};


function getAll(device_serial, map_id, treatment_id, error_code, per_page, page, callback, reject) {
  return SuperAgent
  .get(api_root + "/incidents/all")
  .query({machine_serial: device_serial})
  .query({map_id})
  .query({treatment_id})
  .query({error_code})
  .query({per_page})
  .query({page})
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getPerMap(map_id, callback, reject) {
  return SuperAgent
  .get(api_root + "/incidents/analytics/map/" + map_id)
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getPerDeviceSerial(device_serial, callback, reject) {
  return SuperAgent
  .get(api_root + "/incidents/analytics/machine/" + device_serial)
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getPerErrorCode(callback, reject) {
  return SuperAgent
  .get(api_root + "/incidents/analytics/error_codes")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getIncidentRecord(incident_id, callback, reject) {
  return SuperAgent
  .get(api_root + "/incidents/detail/" + incident_id)
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}


function downloadFile(incident_id, file_name, downloaded_file_name, callback) {
  return SuperAgent
  .get(api_root + "/incidents/download/" + incident_id + "/" + file_name)
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .responseType('arraybuffer')
  .then((response) => {
    const FileSaver = require('file-saver');
    const blob = new Blob([response.body], {type: response.type});
    FileSaver.saveAs(blob, downloaded_file_name)
    callback()
  })
  .catch(errorHandling)
}


function exportFile(machine_list, start_time, end_time, downloaded_file_name, callback, revoke) {
  return SuperAgent
  .post(api_root + "/incidents/export")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .send({machine_list})
  .send({start_time})
  .send({end_time})
  .use(unauthorizedRedirect)
  .responseType('arraybuffer')
  .then((response) => {
    const FileSaver = require('file-saver');
    const blob = new Blob([response.body], {type: response.type});
    FileSaver.saveAs(blob, downloaded_file_name)
    callback()
  })
  .catch(errorHandling)
  .catch(revoke)
}


function retryPostProcessing(incident_id, post_processing_type, callback) {
  return SuperAgent
  .get(api_root + "/incidents/post_processing/" + incident_id + "/retry/" + post_processing_type)
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(errorHandling)
}