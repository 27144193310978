import {deviceFleetConstants} from '_constants'

export const deviceFleetActions = {
  updateSerial,
  updateAll,
  updateErrorCodes,
  updateCommissionedDeviceList,
  updateDeviceState,
  updateDeviceData
};


function updateAll(all_devices) {
  return {type: deviceFleetConstants.UPDATE_ALL_DEVICES, all_devices}
}

function updateCommissionedDeviceList(all_commissioned_devices) {
  return {type: deviceFleetConstants.UPDATE_ALL_COMMISSIONED_DEVICES, all_commissioned_devices}
}

function updateSerial(device_serial) {
  localStorage.setItem("selected_device_serial", device_serial)
  return {type: deviceFleetConstants.UPDATE_DEVICE_SERIAL, device_serial}
}

function updateErrorCodes(error_codes) {
  return {type: deviceFleetConstants.UPDATE_ERROR_CODES, error_codes}
}

function updateDeviceState(device_state) {
  return {type: deviceFleetConstants.UPDATE_DEVICE_STATE, device_state}
}

function updateDeviceData(device_serial, device_data) {
  return {type: deviceFleetConstants.UPDATE_DEVICE_DATA, device_serial, device_data}
}