import React, {useState} from "react"
import Button from "../CustomButtons"
import {useAuth} from "react-oidc-context"
import CustomInput from "../CustomInput";
import {loginService} from "../../_services";
import {Alert} from "@mui/material";

const RedirectLogin = () => {
  const auth = useAuth()
  const [inputValue, setInputValue] = useState(''); // State for input value
  const [errorMessage, setErrorMessage] = useState(''); // State for input value

  // Handler function for input change
  const handleInputChange = (event) => {
    setInputValue(event.target.value); // Update state with new input value
  };

  const handleLogin = () => {
    console.log(inputValue)
    loginService.getLoginMethod(inputValue, res=>{
      auth.signinPopup({popupWindowFeatures: {width: 450, height: 600, left: 150, top: 100}, login_hint: inputValue})
    }, error=>{
      setErrorMessage("This application is only available to Octiva employees")
      setInputValue("")
    })
  }
  return (
    <div>
      <CustomInput
        labelText={"Email"}
        inputProps={{
          onChange: handleInputChange,
          type: "text",
          value: inputValue
        }}
      />
      {errorMessage &&
          <Alert severity={"warning"}>{errorMessage}</Alert>
      }
      <Button color="primary" onClick={() => {
        handleLogin()
      }}>Log in</Button>
    </div>
  )
}

export default RedirectLogin